import React, { useEffect, useState } from 'react';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QRCode } from '@progress/kendo-react-barcodes';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

import { apiClient } from '../../api/apiClient';
import { USER_GET_all } from '../../api/apiEndpoints';
import NotificationEnum from '../../models/enums/NotificationEnum';
import Loading from '../../pages/Loading';
import useAppNotifications from '../../services/AppNotifications';
import BulkQRGenerator from './BulkQRGenerator';
import EmployeeQRToolbar from './EmployeeQRToolbar';

interface EmployeeData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    specialId: string;
    isActive: boolean;
    department?: string;
    jobPosition?: string;
}

interface ViewState {
    employeesData: EmployeeData[];
    filteredData: EmployeeData[];
    isLoading: boolean;
    showActiveOnly: boolean;
    selectedEmployee: EmployeeData | null;
    qrVisible: boolean;
    bulkQRVisible: boolean;
    currentDate: Date;
}

const INITIAL_VIEW_STATE: ViewState = {
    employeesData: [],
    filteredData: [],
    isLoading: true,
    showActiveOnly: false,
    selectedEmployee: null,
    qrVisible: false,
    bulkQRVisible: false,
    currentDate: new Date()
};

const EmployeeQRView = () => {
    const [viewState, setViewState] = useState<ViewState>(INITIAL_VIEW_STATE);
    const notifications = useAppNotifications();

    const getEmployeesData = async (): Promise<EmployeeData[]> => {
        try {
            const resp = await apiClient({
                method: "GET",
                url: USER_GET_all,
                data: {},
            });

            if (resp.status !== 200) {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error);
                return [];
            }

            // Mapping data from API
            const users = resp.data.result as EmployeeData[];

            if (!users || users.length === 0) {
                return [];
            }

            return users.map(user => ({
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                specialId: user.specialId || user.id, // Fallback to id if specialId doesn't exist
                isActive: user.isActive,
                department: user.department,
                jobPosition: user.jobPosition
            }));
        } catch (error) {
            notifications.showNotification('Wystąpił nieoczekiwany błąd podczas pobierania danych pracowników.', NotificationEnum.Error);
            console.error(error);
            return [];
        }
    };

    useEffect(() => {
        getEmployeesData().then((data) => {
            const filtered = viewState.showActiveOnly
                ? data.filter(emp => emp.isActive !== false)
                : data;

            setViewState(prev => ({
                ...prev,
                employeesData: data,
                filteredData: filtered,
                isLoading: false
            }));
        });
    }, []);

    useEffect(() => {
        const filtered = viewState.showActiveOnly
            ? viewState.employeesData.filter(emp => emp.isActive !== false)
            : viewState.employeesData;

        setViewState(prev => ({
            ...prev,
            filteredData: filtered
        }));
    }, [viewState.showActiveOnly, viewState.employeesData]);

    const toggleQrVisible = (employee: EmployeeData | null = null) => {
        setViewState(prev => ({
            ...prev,
            qrVisible: !prev.qrVisible,
            selectedEmployee: employee
        }));
    };

    const toggleBulkQRVisible = () => {
        setViewState(prev => ({
            ...prev,
            bulkQRVisible: !prev.bulkQRVisible
        }));
    };

    if (viewState.isLoading) {
        return <Loading />;
    }

    return (
        <div className="mdlMain">
            {/* Dialog with single QR code */}
            {viewState.qrVisible && viewState.selectedEmployee && (
                <Dialog
                    title={`Kod QR pracownika: ${viewState.selectedEmployee.firstName} ${viewState.selectedEmployee.lastName}`}
                    onClose={() => toggleQrVisible()}
                    width={500}
                    height={580}
                >
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                        <p><FontAwesomeIcon icon={faUser} /> <strong>{viewState.selectedEmployee.firstName} {viewState.selectedEmployee.lastName}</strong></p>
                        <p>{viewState.selectedEmployee.email}</p>
                        <hr style={{ margin: '15px 0', borderStyle: 'dashed' }} />
                    </div>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <QRCode
                            value={viewState.selectedEmployee.specialId || viewState.selectedEmployee.id}
                            errorCorrection="H"
                            size={400}
                            renderAs='canvas'
                            encoding='UTF_8'
                        />
                    </p>
                    <DialogActionsBar>
                        <Button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={() => toggleQrVisible()}
                        >
                            Zamknij
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}

            <BulkQRGenerator
                employees={viewState.filteredData}
                visible={viewState.bulkQRVisible}
                onClose={toggleBulkQRVisible}
            />

            <div className="mdlToolbar">
                <EmployeeQRToolbar
                    onGenerateAllClick={toggleBulkQRVisible}
                />
            </div>

            <div className="mdlDataView">
                <Grid
                    data={viewState.filteredData}
                    style={{ height: "100%" }}
                >
                    <GridColumn field="firstName" title="Imię" />
                    <GridColumn field="lastName" title="Nazwisko" />
                    <GridColumn field="email" title="Email" />
                    <GridColumn field="department" title="Dział" />
                    <GridColumn field="jobPosition" title="Stanowisko" width="150px" />
                    <GridColumn
                        field="isActive"
                        title="Status"
                        width="100px"
                        cell={(props) => (
                            <td>
                                {props.dataItem.isActive !== false ?
                                    <span style={{ color: 'green' }}>Aktywny</span> :
                                    <span style={{ color: 'red' }}>Nieaktywny</span>}
                            </td>
                        )}
                    />
                    <GridColumn
                        title="Akcje"
                        width="200px"
                        cell={(props) => (
                            <td>
                                <Button
                                    icon='qr-code'
                                    fillMode="outline"
                                    onClick={() => toggleQrVisible(props.dataItem)}
                                >
                                    Pokaż
                                </Button>&nbsp;
                                <Button
                                    icon='email'
                                    fillMode="outline"
                                    onClick={() => toggleQrVisible(props.dataItem)}
                                >
                                    Wyślij
                                </Button>
                            </td>
                        )}
                    />
                </Grid>
            </div>
        </div>
    );
};

export default EmployeeQRView;