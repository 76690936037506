import WorkTimeItemTypeEnum from '../../../dto/Common/WorkTimeItemTypeEnum';

export default class WorkTimePersonalGridItem {
    id: string;
    date: Date;
    hourFrom: Date;
    hourTo: Date | null;
    hours: Date;
    description?: string;

    userName: string;
    userId: string;

    // departmentsNames: string[];
    // departmentsIds: string[];

    isDeleted: boolean;
    deleteReason: string;

    itemType: WorkTimeItemTypeEnum;

    constructor(id: string | any, date: Date | any, hourFrom: Date | any, hourTo: Date | any, hours: Date | any, description?: string | any) {
        this.id = id;
        this.date = date;
        this.hourFrom = hourFrom;
        this.hourTo = hourTo;
        this.hours = hours;
        this.description = description;
    }
}