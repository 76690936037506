import React from 'react';

import { Calendar, CalendarNavigationItem, CalendarProps } from '@progress/kendo-react-dateinputs';

const CustomNavigationItem = (props: any) => {
    return <CalendarNavigationItem {...props}>
        {/* Months count starts from 0 */}
        {`${props.value.getMonth() + 1}.${props.value.getFullYear()}`}
    </CalendarNavigationItem>;
};

const CalendarWithMonths = (props: CalendarProps) => {
    return (
        <Calendar
            {...props}
            navigationItem={CustomNavigationItem}
        />
    );
};

export default CalendarWithMonths;