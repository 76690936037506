import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import {
  Button, Toolbar, ToolbarItem, ToolbarSeparator, ToolbarSpacer
} from '@progress/kendo-react-buttons';
import { FormElement } from '@progress/kendo-react-form';

import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';
import { ViewTypeEnum } from '../../Calendar/ViewTypeEnum';
import WorkTimeGridDepartmentFilter from './WorkTimeGridDepartmentFilter';

const WorkTimeMonthlyTimeLogGridToolbar = (props: {
  date: Date,
  changeMonth: (shift: number) => void,
  resetMonth: () => void,
  exportPDF: () => void,
  exportAttendanceList: () => void,
  showFilters: boolean,
  showPrintTemplateButton: boolean
  showBackToListButton: boolean
}) => {
  const [viewType, setViewType] = React.useState<ViewTypeEnum>(ViewTypeEnum.Month);
  const loader = useAppLoader(true);
  const [selectedDepartment, setSelectedDepartment] = React.useState<string | undefined>();
  const notifications = useAppNotifications();
  const navigate = useNavigate();


  useEffect(() => {
  }, []);

  useEffect(() => {
  }, [selectedDepartment]);

  return (
    <Toolbar>
      {props.showBackToListButton && (
        <>
          <ToolbarItem>
            <FormElement>
              <Button
                fillMode="outline"
                icon="arrow-left"
                className="k-button"
                onClick={() => {
                  navigate(`/worktimeBrowse`);
                }}
                type="button"
              >
                Powrót do listy
              </Button>
            </FormElement>
          </ToolbarItem>
          <ToolbarSeparator></ToolbarSeparator>
        </>)}


      <ToolbarItem>
        <FormElement>
          <Button
            fillMode="outline"
            icon="arrow-chevron-left"
            className="k-button"
            onClick={() => { props.changeMonth(-1) }}
            type="button"
          >
          </Button>
        </FormElement>
      </ToolbarItem>
      <ToolbarItem>
        <FormElement>
          <div style={{ paddingLeft: "10px", paddingRight: "10px", marginTop: "0.4em", fontWeight: "bold" }}>
            {new Intl.DateTimeFormat("pl-PL", { month: 'long', year: 'numeric' }).format(props.date)}
          </div>
        </FormElement>
        <FormElement>
          <Button
            fillMode="outline"
            icon="arrow-chevron-right"
            className="k-button"
            onClick={() => { props.changeMonth(1) }}
            type="button"
          >
          </Button>
        </FormElement>

        &nbsp;
        <ToolbarSeparator></ToolbarSeparator>
        &nbsp;

        <ToolbarItem>
          <FormElement>
            <Button
              fillMode="outline"
              icon="reload"
              className="k-button"
              onClick={() => { props.resetMonth() }}
              type="button"
            >
            </Button>
          </FormElement>
        </ToolbarItem>
      </ToolbarItem>

      {(props.showFilters &&
        <WorkTimeGridDepartmentFilter filterParentByDepartmentId={(departmentId: string | undefined) => { notifications.showNotification("depid:" + departmentId) }} />
      )}

      <ToolbarSeparator></ToolbarSeparator>
      <ToolbarSpacer></ToolbarSpacer>
      <FormElement>
        <Button
          fillMode="outline"
          icon="print"
          className="k-button"
          onClick={() => { props.exportPDF() }}
          type="button"
        >
          PDF
        </Button>
      </FormElement>

      {props.showPrintTemplateButton && (
        <FormElement>
          <Button
            fillMode="outline"
            icon="print"
            className="k-button"
            onClick={() => { props.exportAttendanceList(); }}
            type="button"
            disabled={true}
          >
            Lista obecności
          </Button>
        </FormElement>
      )}
    </Toolbar >
  );
};

export default WorkTimeMonthlyTimeLogGridToolbar;