import { useContext } from 'react';

import NotificationEnum from '../models/enums/NotificationEnum';
import { AppContext, IAppContext } from './AppContext';

const useAppNotifications = () => {
    const context = useContext<IAppContext>(AppContext);


    const showNotification = (message: string | string[], notificationStyle: NotificationEnum = NotificationEnum.Success) => {
        // Sprawdzenie, czy message jest tablicą, i połączenie elementów jeśli tak
        if (Array.isArray(message)) {
            message = message.join(". ");
        }

        context.setValues({ ...context.values, notification: message, notificationStyle: notificationStyle });
        //context.setSession({ ...context.session, notificationStyle: style });
    };

    return { showNotification }
}

export default useAppNotifications;