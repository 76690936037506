import React from 'react';

import { LeavesStatesReport } from '../components/Reports/LeavesStatesReport';

const LeavesStatesReportPage = () => {
  return (
    <div className="mdlRoot">
      <LeavesStatesReport />
    </div>
  );
}

export default LeavesStatesReportPage;