import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';

import RegulaminSynerdi from '../_documents/RegulaminSynerdi202309.pdf';
import { apiClient } from '../api/apiClient';
import { OFFICE_POST_register } from '../api/apiEndpoints';
import { FormInput } from '../components/Kendo/form-components';
import PromoBanner from '../components/LoginRegister/PromoBanner';
import NotificationEnum from '../models/enums/NotificationEnum';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';
import moduleStyle from '../styles/LoginRegister.module.scss';
import {
  emailValidator, lastNameValidator, nameValidator, nipValidator, phoneValidator,
  requiredMinLength6Validator
} from '../validators/CommonValidators';

const Register = () => {
  const [isSuccessNotificationVisible, setIsSuccessNotificationVisible] = useState(false);
  const navigate = useNavigate();
  const loader = useAppLoader(false);
  const notifications = useAppNotifications();

  const handleSubmit = async (registerForm: any) => {
    loader.showLoading(true);

    apiClient({
      method: "POST",
      url: OFFICE_POST_register,
      data: {
        name: registerForm.name,
        nip: registerForm.nip,
        promoCode: registerForm.promoCode,
        firstUser: {
          personalData: {
            firstName: registerForm.firstName,
            lastName: registerForm.lastName,
            phoneNumber: registerForm.phoneNumber
          },
          email: registerForm.email,
          password: registerForm.password,
        },
      },
    }).then(async (resp) => {
      loader.showLoading(false);
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      setIsSuccessNotificationVisible(true);
    });
  };

  return (
    <div id="registerPageWrapper" className={moduleStyle.pageWrapper} >
      <div className={moduleStyle.formColumn} style={{ minHeight: '625px' }} >
        {!isSuccessNotificationVisible && (
          <Form
            onSubmit={handleSubmit}
            initialValues={{ email: "", password: "" }}
            render={() => (
              <div style={{ width: "400px" }}>
                <p className={moduleStyle.formHeader}>Tworzenie konta</p>

                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem !important",
                    lineHeight: "1.5rem",
                  }}
                >
                  Załóż <b>bezpłatne</b> konto i testuj pełną funkcjonalność systemu - bez żadnych zobowiązań!
                </p>

                <FormElement
                  style={{
                    width: 400,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ width: "65%" }}>
                        <Field
                          id={"name"}
                          name={"name"}
                          autoComplete="new-password"
                          label={"Nazwa firmy"}
                          component={FormInput}
                          validator={requiredMinLength6Validator}
                        />
                      </div>

                      <div style={{ width: "35%", marginLeft: "10%" }}>
                        <Field
                          id={"nip"}
                          name={"nip"}
                          autoComplete="new-password"
                          label={"Numer NIP"}
                          component={FormInput}
                          validator={nipValidator}
                        />
                      </div>
                    </div>

                    {/* <Field
                      id={"promoCode"}
                      name={"promoCode"}
                      autoComplete="new-password"
                      label={"Kod zaproszenia"}
                      component={FormInput}
                    // validator={requiredValidator}
                    /> */}

                    <fieldset className={"k-form-fieldset"}>
                      <legend className={"k-form-legend"}>
                        Dane głównego użytkownika:
                      </legend>
                      <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ width: "45%" }}>
                          <Field
                            id={"firstName"}
                            name={"firstName"}
                            autoComplete="firstname"
                            label={"Imię"}
                            component={FormInput}
                            validator={nameValidator}
                          />
                        </div>
                        <div style={{ width: "45%", marginLeft: "10%" }}>
                          <Field
                            id={"lastName"}
                            name={"lastName"}
                            autoComplete="lastname"
                            label={"Nazwisko"}
                            component={FormInput}
                            validator={lastNameValidator}
                          />
                        </div>
                      </div>

                      <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ width: "45%" }}>
                          <Field
                            id={"phoneNumber"}
                            name={"phoneNumber"}
                            autoComplete="phoneNumber"
                            label={"Telefon"}
                            component={FormInput}
                            validator={phoneValidator}
                          />
                        </div>
                      </div>

                      <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ width: "45%" }}>
                          <Field
                            id={"email"}
                            name={"email"}
                            autoComplete="email"
                            label={"Adres e-mail (login)"}
                            component={FormInput}
                            validator={emailValidator}
                          />
                        </div>
                        <div style={{ width: "45%", marginLeft: "10%" }}>
                          <Field
                            id={"password"}
                            name={"password"}
                            type="password"
                            autoComplete="new-password"
                            label={"Hasło"}
                            component={FormInput}
                            validator={requiredMinLength6Validator}
                          />
                        </div>
                      </div>

                    </fieldset>
                  </div>

                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <div style={{ width: "50%" }}>
                      <Button type="submit" disabled={loader.isLoading()} style={{ fontWeight: "bold" }}>Utwórz bezpłatne konto</Button>
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <Button onClick={(e) => { e.preventDefault(); navigate(-1); }} > Anuluj </Button>
                    </div>
                  </div>

                  <p className={moduleStyle.licenceNote}>
                    Zakładając konto potwierdzasz, że zapoznałeś się z{" "}
                    <a href={RegulaminSynerdi}>Regulaminem usługi wraz z umową powierzenia przetwarzania danych osobowych</a>{" "}
                    i akceptujesz spisane tam postanowienia.
                  </p>

                </FormElement>


              </div>
            )
            }
          />
        )}

        {
          isSuccessNotificationVisible && (
            <p style={{ width: "400px", textAlign: "justify" }}>
              <p>
                Dziękujemy za wypełnienie formularza!<br />
                Twoje konto jest już prawie gotowe.<br />
              </p>
              <p><span className="k-icon k-i-letter" style={{ fontSize: "4em", marginLeft: "-5px", color: 'lightgreen' }} /></p>
              Za chwilę otrzymasz <b>e-mail z linkiem aktywacyjnym</b>. Kliknij w niego, aby dokończyć proces rejestracji i w pełni korzystać z naszej platformy.
            </p>
          )
        }
      </div >
      <PromoBanner />
    </div >
  );
};

export default Register;
