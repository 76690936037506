import React from 'react';

import { processTreeViewItems, TreeView } from '@progress/kendo-react-treeview';

import DepartmentTreeItem from '../../models/local/Departments/DepartmentTreeItem';

const DepartmentsList = (props: {
  disabled: boolean,
  departments: DepartmentTreeItem[],
  onSelectDepartment: (event: any) => void
}) => {


  props.departments.forEach(department => {
    department.disabled = props.disabled;
  });

  return (
    <div
      style={{ minHeight: "6em", width: "100%", height: "calc(100% - 6em )" }}
    >
      <div className="itemHeader">Struktura organizacyjna</div>

      <TreeView
        aria-multiselectable={false}
        data={processTreeViewItems(props.departments, { selectField: 'selected', })}
        expandIcons={true}
        onItemClick={props.onSelectDepartment}
      />
    </div>
  );
};

export default DepartmentsList;
