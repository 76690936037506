import React from 'react';
import { useNavigate } from 'react-router';

import UserRoleEnum from '../models/enums/UserRoleEnum';
import { Dict, GetAllDictsAndEnums } from '../scripts/dictProvider';
import { AppContext, AppContextModel, IAppContext } from './AppContext';

const useSessionWrapper = () => {
    const context = React.useContext<IAppContext>(AppContext);
    const navigate = useNavigate();

    const login = async (
        accessToken: string,
        userName: string,
        userId: string,
        role: string,
        licenceExpirationDays: number,
        currentDateTime: Date,
        specialId: string,
        avatarId?: string,
        forceRedirectUrl?: string
    ) => {
        console.log("SessionWrapper: clearing and setting localStorage... isInMobileView=" + context.values.isInMobileView)

        clearLocalStorageExceptMobileSettings();

        localStorage.setItem("access-token", accessToken);
        localStorage.setItem("userName", userName);
        localStorage.setItem("avatarId", avatarId ?? '');
        localStorage.setItem("userId", userId);
        localStorage.setItem("specialId", specialId); // Dodana linia
        localStorage.setItem("role", role.toUpperCase());
        localStorage.setItem("licenceExpirationDays", licenceExpirationDays?.toString());
        localStorage.setItem("currentDateTime", currentDateTime.toString());

        await localStorageToContextCopy();

        console.log("-----------> REDIRECT !")

        if (forceRedirectUrl) {
            navigate(forceRedirectUrl);
        } else if (licenceExpirationDays < 0 && role !== UserRoleEnum.ADMINISTRATOR) {
            navigate("/settingsLicence");
        } else {
            navigate("/");
        }
    }

    const localStorageToContextCopy = (dontRedirect: boolean = false) => {
        localStorageMobileViewToContextCopy();

        console.log("localStorageToContextCopy:  -> access-token exists; do job.")

        // Check if specialId exists in localStorage
        const specialIdExists = localStorage.getItem("specialId") !== null &&
            localStorage.getItem("specialId") !== "";

        // If user is logged in (access-token exists) but specialId is missing, force logout
        if (localStorage.getItem("access-token") && !specialIdExists) {
            console.log("SessionWrapper: missing specialId in localStorage - forcing logout");
            logout(true);
            return;
        }

        let dictionaries: Dict[] = [];
        const getAllDicts = async () => {
            dictionaries = await GetAllDictsAndEnums();
        }
        getAllDicts().then(() => {
            context.setValues(prev => ({
                ...prev,
                loggedIn: localStorage.getItem("access-token") ? true : false,
                userName: localStorage.getItem("userName")!,
                userId: localStorage.getItem("userId")!,
                specialId: localStorage.getItem("specialId") || "", // Ensure specialId is handled
                role: UserRoleEnum[(localStorage.getItem("role") as keyof typeof UserRoleEnum)],
                avatarId: localStorage.getItem("avatarId"),
                dicts: dictionaries,

                currentDateTime: new Date(localStorage.getItem("currentDateTime")!),
            }));
        });
    }

    const localStorageMobileViewToContextCopy = () => {
        console.log("localStorageMobileViewToContextCopy:  -> isInMobileView=" + localStorage.getItem("isInMobileView"))

        context.setValues(prev => ({
            ...prev,
            isInMobileView: localStorage.getItem("isInMobileView") === "true",
            mobileBreakpointPx: parseInt(localStorage.getItem("mobileBreakpointPx")!),
        }));
    }

    const logout = async (dontRedirect: boolean = false) => {
        console.log("SessionWrapper:  -> logout.")

        await clearLocalStorageExceptMobileSettings();

        context.setValues({ ...{} as AppContextModel, loggedIn: false });
        if (!dontRedirect)
            navigate("/");
    }

    const isLoggedIn = () => {
        return context.values.loggedIn;
    }

    const clearLocalStorageExceptMobileSettings = async () => {
        // Store mobile view settings
        const isInMobileView = localStorage.getItem("isInMobileView");
        const mobileBreakpointPx = localStorage.getItem("mobileBreakpointPx");

        localStorage.clear();

        // Restore mobile view settings
        if (isInMobileView !== null) {
            localStorage.setItem("isInMobileView", isInMobileView);
        }
        if (mobileBreakpointPx !== null) {
            localStorage.setItem("mobileBreakpointPx", mobileBreakpointPx);
        }

        // Update context to maintain app state consistency
        await localStorageToContextCopy();
    };

    return { logout, login, isLoggedIn, localStorageToContextCopy, localStorageMobileViewToContextCopy, clearLocalStorageExceptMobileSettings }
}

export default useSessionWrapper;