import React, { useState } from 'react';

import { Button } from '@progress/kendo-react-buttons';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';

interface LocationData {
    latitude: number;
    longitude: number;
    accuracy: number;
    timestamp: number;
}

const GPSTestPage: React.FC = () => {
    const [location, setLocation] = useState<LocationData | null>(null);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const getLocation = () => {
        setIsLoading(true);
        setError('');
        setShowNotification(false);

        if (!navigator.geolocation) {
            setError('Geolokalizacja nie jest wspierana przez twoją przeglądarkę');
            setShowNotification(true);
            setIsLoading(false);
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    accuracy: position.coords.accuracy,
                    timestamp: position.timestamp
                });
                setIsLoading(false);
            },
            (error) => {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        setError('Użytkownik odmówił dostępu do geolokalizacji');
                        break;
                    case error.POSITION_UNAVAILABLE:
                        setError('Informacja o lokalizacji jest niedostępna');
                        break;
                    case error.TIMEOUT:
                        setError('Przekroczono czas oczekiwania na pobranie lokalizacji');
                        break;
                    default:
                        setError('Wystąpił nieznany błąd');
                }
                setShowNotification(true);
                setIsLoading(false);
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
        );
    };

    return (
        <div className="k-m-4">
            <NotificationGroup>
                {showNotification && error && (
                    <Notification
                        type={{ style: 'error', icon: true }}
                        closable={true}
                        onClose={() => setShowNotification(false)}
                    >
                        <span>{error}</span>
                    </Notification>
                )}
            </NotificationGroup>

            <Card>
                <CardBody>
                    <Button
                        themeColor={'primary'}
                        disabled={isLoading}
                        onClick={getLocation}
                        icon="map-marker"
                    >
                        {isLoading ? 'Pobieranie lokalizacji...' : 'Pobierz aktualną lokalizację'}
                    </Button>

                    {location && (
                        <div className="k-mt-4">
                            <div className="k-mb-2">
                                <strong>Szerokość geograficzna:</strong> {location.latitude}
                            </div>
                            <div className="k-mb-2">
                                <strong>Długość geograficzna:</strong> {location.longitude}
                            </div>
                            <div className="k-mb-2">
                                <strong>Dokładność:</strong> {Math.round(location.accuracy)}m
                            </div>
                            <div className="k-mb-2">
                                <strong>Czas pobrania:</strong>{' '}
                                {new Date(location.timestamp).toLocaleString()}
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

export default GPSTestPage;