import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';

import { apiClient } from '../../../api/apiClient';
import { LEAVE_GETBYID, LEAVE_PUT_changeStatus } from '../../../api/apiEndpoints';
import LeaveDto from '../../../models/dto/Leaves/LeaveDto';
import LeaveDetailsResponseDto from '../../../models/dto/Leaves/Responses/LeaveDetailsResponseDto';
import LeaveStatusEnum, { LeaveStatusEnumTranslation } from '../../../models/enums/LeaveStatusEnum';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import Leave from '../../../models/local/Leaves/Leave';
import LeaveDetails from '../../../models/local/Leaves/LeaveDetails';
import { mapper } from '../../../models/mapper';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';
import PromptDialog from '../../Common/PromptDialog';
import {
  FormDatePicker, FormInput, FormMultiSelect, FormTextArea
} from '../../Kendo/form-components';

type AppIdParams = {
  id: string;
};

const ApplicationConsider = () => {

  const loader = useAppLoader(true);
  const [leave, setLeave] = React.useState<Leave>();
  const [promptDialogStatus, setPromptDialogStatus] = React.useState<{ visible: boolean, accept: boolean }>({ visible: false, accept: false });
  const notifications = useAppNotifications();

  const appId = useParams<AppIdParams>()?.id;
  const navigate = useNavigate();

  const loadData = () => {
    loader.showLoading(true);
    apiClient({
      method: "GET",
      url: LEAVE_GETBYID(appId),
      data: {},
    }).then((resp) => {


      const mappedResult: Leave = mapper.map(resp?.data?.result, LeaveDetails, LeaveDetailsResponseDto);

      setLeave(mappedResult);
      loader.showLoading(false);
    });
  }

  React.useEffect(() => {
    loadData();
  }, []);

  const changeStatus = (accept: boolean) => {
    loader.showLoading(true);
    apiClient({
      method: "PUT",
      url: LEAVE_PUT_changeStatus,
      data: {
        id: leave?.id,
        status: accept ? LeaveStatusEnum.ACCEPTED : LeaveStatusEnum.REFUSED
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        return;
      }
      notifications.showNotification(accept ? "Potwierdzenie: wniosek został zaakceptowany" : "Potwierdzenie: wniosek został odrzucony", NotificationEnum.Success);
      navigate("/appslist");
    });
  };

  return (
    <div className="fullPageContentWrapper">
      <div className="fullWidthColumn">
        <div className="itemHeader">
          Rozpatrywanie złożonego wniosku
        </div>

        {promptDialogStatus.visible === true && (
          <PromptDialog
            text={`Czy na pewno chcesz ${promptDialogStatus.accept ? "ZAAKCEPTOWAĆ" : "ODRZUCIĆ"} wybrany wniosek ?`}
            onYes={() => { changeStatus(promptDialogStatus.accept); }}
            onNo={() => { setPromptDialogStatus({ ...promptDialogStatus, visible: false }) }}
          />
        )}

        {!loader.isLoading() && <>
          <Toolbar style={{ width: "100%" }}>
            <ToolbarItem>
              <Button
                icon="undo"
                fillMode="outline"
                onClick={() => { navigate("/appslist") }}>
                Powrót
              </Button>
            </ToolbarItem>

            <ToolbarSeparator />

            {leave?.status === LeaveStatusEnum.AWAITING &&
              <ToolbarItem>
                <Button
                  icon="check"
                  fillMode="solid"
                  themeColor="success"
                  onClick={() => { setPromptDialogStatus({ visible: true, accept: true }); }}>
                  Akceptuj wniosek
                </Button>

                &nbsp;

                <Button
                  icon="close"
                  themeColor="error"
                  fillMode="solid"
                  onClick={() => { setPromptDialogStatus({ visible: true, accept: false }); }}>
                  Odrzuć wniosek
                </Button>
              </ToolbarItem>
            }

          </Toolbar>

          <br />

          <Form
            onSubmit={() => { }}
            initialValues={leave}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement
                style={{
                  width: 400,
                }}
              >
                <span>Aktualny status wniosku: <b>{LeaveStatusEnumTranslation(leave?.status)}</b></span>

                <Field
                  id={"ownerName"}
                  name={"ownerName"}
                  label={"Dotyczy"}
                  component={FormInput}
                  disabled={true}
                />

                <Field
                  id={"applicantUserName"}
                  name={"applicantUserName"}
                  label={"Składający wniosek"}
                  component={FormInput}
                  disabled={true}
                />

                <Field
                  id={"limitType"}
                  name={"limitType"}
                  label={"Nieobecność"}
                  component={FormInput}
                  disabled={true}
                />

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="k-form-field" style={{ width: "45%" }}>
                    <Field
                      id={"startDate"}
                      name={"startDate"}
                      label={"Data rozpoczęcia"}
                      component={FormDatePicker}
                      disabled={true}
                    />
                  </div>

                  <div className="k-form-field" style={{ width: "45%" }}>
                    <Field
                      id={"endDate"}
                      name={"endDate"}
                      label={"Data zakończenia"}
                      component={FormDatePicker}
                      disabled={true}
                    />
                  </div>
                </div>

                <Field
                  id={"replacementUsers"}
                  name={"replacementUsers"}
                  label={"Osoby zastępujące podczas nieobecności"}
                  textField="text"
                  component={FormMultiSelect}
                  data={leave?.replacementUsers}
                  dataItemKey="id"
                  disabled={true}
                />

                <Field
                  id={"comment"}
                  name={"comment"}
                  label={"Komentarz składającego wniosek"}
                  component={FormTextArea}
                  disabled={true}
                />

                <Field
                  id={"hrComment"}
                  name={"hrComment"}
                  label={"Komentarz działu kadr"}
                  component={FormTextArea}
                  disabled={true}
                />

              </FormElement>
            )}
          />
        </>
        }
      </div>
    </div >
  );
}

export default ApplicationConsider;