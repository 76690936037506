import React, { useEffect } from 'react';

import { process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column, GridDetailRowProps } from '@progress/kendo-react-grid';

import LimitTypeOverrideItem from '../../../../../../models/local/Employees/LimitTypeOverrideItem';
import PromptDialog from '../../../../../Common/PromptDialog';

export const LimitDetailGridComponent = (props: { items: GridDetailRowProps, year: number, onDeleteItem: (item: LimitTypeOverrideItem) => void, onEditItem: (item: LimitTypeOverrideItem) => void, readonly: boolean }) => {
  const data = props.items.dataItem.items as LimitTypeOverrideItem[];

  const [viewState, setViewState] = React.useState<{ confirmDeleteDialogVisible: boolean, itemToDelete?: LimitTypeOverrideItem }>(
    {
      confirmDeleteDialogVisible: false,
      itemToDelete: undefined
    }
  );

  const onDeleteItem = (item: LimitTypeOverrideItem) => {
    props.onDeleteItem(item);
    setViewState({ ...viewState, itemToDelete: undefined, confirmDeleteDialogVisible: false });
  }

  useEffect(() => {
  }, []);

  if (data) {
    return (
      <>

        {viewState.confirmDeleteDialogVisible && (
          <PromptDialog
            text={`<p>Czy na pewno chcesz usunąć tą pozycję ?</p>` +
              `Usunięcie limitu spowoduje zmniejszenie ilości przysługującego pracownikowi limitu urlopu w roku ${props.year}.`}
            onYes={() => { onDeleteItem(viewState.itemToDelete!); }}
            onNo={() => { setViewState({ ...viewState, itemToDelete: undefined, confirmDeleteDialogVisible: false }) }}
          />
        )}

        <Grid
          data={process(data, { sort: [{ field: "validFromDate", dir: "asc" }] })}
          sort={[{ field: "validFromDate", dir: "asc" }]} sortable={true}
        >
          <Column field="validFromDate" title="Dostępny od" width="175px" format="{0:d}" />
          <Column field="limit" title="Ilość dni" />
          {!props.readonly && <Column
            title="Operacje"
            width="200px"
            cell={(v: any) => {
              const isYearOpeningItem = new Date(v.dataItem.validFromDate).toDateString() === new Date(props.year, 0, 1).toDateString();

              return (
                <td style={{ textAlign: "right" }}>
                  {!isYearOpeningItem && <Button fillMode="outline" icon="trash" onClick={() => { setViewState({ ...viewState, itemToDelete: (v.dataItem) as LimitTypeOverrideItem, confirmDeleteDialogVisible: true }) }} >Usuń</Button>}
                  {" "}
                  <Button fillMode="outline" icon="pencil" onClick={() => { props.onEditItem(v.dataItem) }} >Edytuj</Button>
                </td>
              )
            }}
          />}
        </Grid>
        <hr />
      </>
    );
  }
  return (
    <div style={{ height: "50px", width: "100%" }}>
      <div style={{ position: "absolute", width: "100%" }}>
        <div className="k-loading-image" />
      </div>
    </div>
  );
};