import React, { useState } from 'react';

import DropdownItem from '../../../models/local/Dropdown/DropdownItem';
import LimitType from '../../../models/local/Employees/LimitType';
import LimitTypeOverride from '../../../models/local/Employees/LimitTypeOverride';
import LeavesGroupedByType from '../../../models/local/Leaves/LeavesGroupedByType';
import UserLeaveStats from '../../../models/local/Leaves/UserLeaveStats';
import AllUserLimits from '../../../models/local/Limits/AllUserLimits';
import CustomProgressBar from '../../_common/CustomProgressBar/CustomProgressBar';

export const AppAppFormExplanations = (props: {
  selectedLimitType: DropdownItem | undefined,

  currentAppWorkingDaysSum: number,
  currentAppAllDaysSum: number,

  userLimits: AllUserLimits,
  userLeaveStats: UserLeaveStats,

  isOverlappingWithExistingRequest: boolean
}) => {

  const [viewState, setViewState] = useState<{ dbUsed: number | null, dbTotal: number | null, isLimitExceeded: boolean }>({
    dbUsed: null,
    dbTotal: null,
    isLimitExceeded: false
  });

  const coversAnyWorkingDay = props?.currentAppWorkingDaysSum !== 0;
  const backgroundColor = coversAnyWorkingDay && !props.isOverlappingWithExistingRequest ? "#282456" : "#e9282e";

  React.useEffect(() => {
    if (props.selectedLimitType?.id != null) {
      const dbUsedLeaveDaysSum = findLeaveStatsByTypeId(props.userLeaveStats.leavesGroupedByType, props.selectedLimitType.id);
      const dbTotalLeaveDaysLimit = calculateTotalLeaveDaysLimit(props.userLimits.individual, props.userLimits.global, props.selectedLimitType.id);

      if (dbUsedLeaveDaysSum != null) {
        setViewState((prev) => ({ ...prev, dbUsed: dbUsedLeaveDaysSum.count, dbTotal: dbTotalLeaveDaysLimit, isLimitExceeded: false }));
      } else {
        setViewState(prev => ({ ...prev, dbUsed: null, dbTotal: dbTotalLeaveDaysLimit, isLimitExceeded: false }));
      }
    } else {
      setViewState({ dbTotal: null, dbUsed: null, isLimitExceeded: false });
    }

  }, [props.selectedLimitType]);

  React.useEffect(() => {
    const isLimitExceeded = (viewState.dbUsed ?? 0) + props.currentAppWorkingDaysSum > (viewState.dbTotal ?? 0);
    setViewState((prev) => ({ ...prev, isLimitExceeded: isLimitExceeded }));
  }, [viewState.dbUsed, viewState.dbTotal, props.currentAppWorkingDaysSum]);

  return <><div style={{ marginTop: "10px", marginBottom: "35px", padding: "20px", paddingBottom: "20px", backgroundColor, color: "white", borderRadius: "5px" }}>
    <div style={{ width: "100%", textAlign: "justify" }}>

      {(!coversAnyWorkingDay && !props.isOverlappingWithExistingRequest) &&
        <span>
          <span className="k-icon k-i-warning"></span>
          Nie można złożyć wniosku ponieważ wskazany zakres dat <b>nie obejmuje dni pracujących</b> wynikających z przypisanego do pracownika kalendarza pracy i planu pracy.<br />
          <b>Upewnij się, że wprowadzone daty rozpoczęcia i zakończenia są poprawne.</b>
        </span>
      }

      {(coversAnyWorkingDay && !props.isOverlappingWithExistingRequest) &&
        <span>
          <span className="k-icon k-i-information"></span> Wniosek zostanie wystawiony na <b>{declineDays(props.currentAppWorkingDaysSum, "robocz")}</b> ({declineDays(props.currentAppAllDaysSum, "kalendarzow")}).<br />

          {(props.selectedLimitType != null) &&
            <span>
              <span className="k-icon k-i-calendar"></span> {getDaysString(viewState.dbTotal, viewState.dbUsed ?? 0, props.selectedLimitType!.text)}.
              <CustomProgressBar requested={viewState.dbUsed!} predicted={props.currentAppWorkingDaysSum} max={viewState.dbTotal} />
            </span>}

        </span>}

      {props.isOverlappingWithExistingRequest &&
        <span style={{ fontWeight: "bold" }}>
          <div style={{ color: "#fff", fontWeight: "bold" }}><span className="k-icon k-i-error" /> Nie można złożyć wniosku ponieważ w systemie <b>znajduje się już inny wniosek</b> pracownika z pokrywającym się terminem. Przed złożeniem wniosku we wskazanym zakresie dat należy wycofać wcześniej złożony wniosek.</div>
        </span>}

    </div>
  </div>
  </>
}

const findLeaveStatsByTypeId = (leavesGroupedByType: LeavesGroupedByType[], leaveTypeId: string): LeavesGroupedByType | undefined => {
  return leavesGroupedByType.find(item => item.leaveTypeId === leaveTypeId);
};

const calculateTotalLeaveDaysLimit = (individual: LimitTypeOverride[], global: LimitType[], id: string): number | null => {
  const findInArray = <T extends LimitTypeOverride | LimitType>(arr: T[]): number | null => {
    for (const item of arr) {
      if ((item instanceof LimitTypeOverride && item.parentId === id) || item.id === id) {
        return item.sum;
      }
    }
    return null;
  }

  const individualSum = findInArray(individual);
  const globalSum = findInArray(global);

  return individualSum && globalSum ? individualSum! + globalSum! : individualSum || globalSum || null;
}

const getDaysString = (days: number | null, used: number, leaveType: string): string => {
  switch (days) {
    case null:
      return leaveType + " (ilość dostępnych dni bez limitu)";
    default:
      return leaveType + ": wykorzystano " + used + " z " + days + " dostępnych dni";
  }
}

export const declineDays = (days: number, word: string): string => {
  if (days === 1) {
    return `1 dzień ${word}y`;
  } else if (days > 1 && days < 5) {
    return `${days} dni ${word}e`;
  } else {
    return `${days} dni ${word}ych`;
  }
}

export default AppAppFormExplanations;