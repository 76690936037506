import React from 'react';
import { useNavigate } from 'react-router';

import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';

import { apiClient } from '../../api/apiClient';
import { LICENCE_DELETE } from '../../api/apiEndpoints';
import MotivationImg from '../../assets/motywacja-sprzedaz.png';
import AppModulesEnum from '../../models/dto/Common/AppModulesEnum';
import LicenceListItemResponse from '../../models/dto/Response/Admin/LicenceListItemResponse';
import NotificationEnum from '../../models/enums/NotificationEnum';
import LicenceList from '../../models/local/Admin/LicenceList';
import { mapper } from '../../models/mapper';
import { AppContext, IAppContext } from '../../services/AppContext';
import useAppNotifications from '../../services/AppNotifications';
import PromptDialog from '../Common/PromptDialog';
import { PaginatedDataGrid } from '../Kendo/PaginatedDataGrid';

interface LicencesListProps {
  url: string;
}

interface ViewState {
  promptDialogVisible: boolean;
  motivationDialogVisible: boolean;
  selectedId: string | null;
  selectedName: string | null;
  errorMessage: string | null;
  filter: CompositeFilterDescriptor;
  sort: Array<SortDescriptor>;
}

const INITIAL_FILTER: CompositeFilterDescriptor = {
  logic: "and",
  filters: [
    { field: "officeName", operator: "contains", value: "" }
  ],
};

const INITIAL_SORT: Array<SortDescriptor> = [
  { field: "validFrom", dir: "desc" },
];

const INITIAL_VIEW_STATE: ViewState = {
  promptDialogVisible: false,
  motivationDialogVisible: false,
  selectedId: null,
  selectedName: null,
  errorMessage: null,
  filter: INITIAL_FILTER,
  sort: INITIAL_SORT
};

const useLicenceDeletion = () => {
  const navigate = useNavigate();
  const notifications = useAppNotifications();

  const deleteLicence = async (licenceId: string): Promise<boolean> => {
    try {
      const response = await apiClient({
        method: "DELETE",
        url: LICENCE_DELETE(licenceId),
        data: {},
      });

      if (response.status === 200) {
        notifications.showNotification("Licencja została usunięta.");
        navigate("/");
        return true;
      }

      return false;
    } catch (error) {
      console.error('Error deleting licence:', error);
      return false;
    }
  };

  return { deleteLicence };
};

const MotivationDialog = ({ onClose }: { onClose: () => void; }) => (
  <Dialog title="Informacja" width="60%" onClose={onClose}>
    <div style={{ display: "flex", gap: "20px" }}>
      <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px" }}>
        <span>
          Każde <b>nowe konto w naszym systemie to <span style={{ color: "red" }}>potencjalny klient</span></b>!
          <br />
          Pamiętaj, że osoba, która założyła konto poświęciła swój czas i <span style={{ color: "red", fontWeight: "bold" }}>jest dla nas bardzo cennym kontaktem</span>, o którego należy zadbać z najwyższą starannością.
        </span>

        <p>
          <b>Co należy zrobić?</b>
          <ol>
            <li><strong>Skontaktuj się <u>bezpośrednio z działem kadr lub HR klienta</u></strong> – to oni decydują o wykorzystaniu naszego programu do zarządzania urlopami.</li>
            <li><strong>Proponuj bezpłatną prezentację</strong> – każda rozmowa telefoniczna powinna zawierać propozycję umówienia się na bezpłatną prezentację naszego programu.</li>
            <li><strong>Zbieraj feedback</strong> – <u>dowiedz się, dlaczego klient założył konto, czy udało mu się zalogować i przetestować program, jak jest zadowolony, czy potrzebuje pomocy lub wyjaśnień.</u></li>
          </ol>
        </p>

        <span>
          <b>Dlaczego to ważne?</b> Każdy nowy kontakt to szansa na rozwój naszej firmy. Dbając o naszych klientów, budujemy zaufanie i długotrwałe relacje, które przynoszą korzyści obu stronom. Wasze zaangażowanie i profesjonalizm są kluczem do naszego wspólnego sukcesu.
        </span>

        <span>
          <b>Pamiętaj!</b> Każdy telefon to <span style={{ color: "red", fontWeight: "bold" }}>okazja do pokazania, jak fajny jest nasz produkt!</span> Twoja energia i entuzjazm mogą przekonać klienta do podjęcia decyzji o współpracy z nami. Działajmy z przekonaniem, że <span style={{ color: "red", fontWeight: "bold" }}>każdy nowy kontakt stanie się naszym stałym klientem!</span>
        </span>
      </div>
      <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img alt="Synerdi.pl" src={MotivationImg} style={{ width: "100%" }} />
      </div>
    </div>
  </Dialog>
);

const GridModulesCell = ({ dataItem }: {
  dataItem: LicenceList;
}) => (
  <td>
    {dataItem.availableModules.map((module: any) => (
      <span>
        {module.id}
        <br />
      </span>
    ))}
  </td>
);

const GridOperationsCell = ({
  dataItem,
  onEdit,
  onDelete
}: {
  dataItem: LicenceList;
  onEdit: (id: string) => void;
  onDelete: (id: string, name: string) => void;
}) => (
  <td>
    <Button
      fillMode="outline"
      icon="edit"
      onClick={() => onEdit(dataItem.id)}
    />
    {" "}
    <Button
      fillMode="outline"
      icon="trash"
      themeColor="error"
      onClick={() => onDelete(dataItem.id, dataItem.officeName)}
    />
  </td>
);

export const LicencesList = ({ url }: LicencesListProps) => {
  const navigate = useNavigate();
  const [viewState, setViewState] = React.useState<ViewState>(INITIAL_VIEW_STATE);
  const { deleteLicence } = useLicenceDeletion();
  const notifications = useAppNotifications();
  const context = React.useContext<IAppContext>(AppContext);

  const handleDeleteButtonClick = (id: string, name: string) => {
    setViewState(prev => ({
      ...prev,
      selectedId: id,
      selectedName: name,
      promptDialogVisible: true
    }));
  };

  const handleDeleteConfirm = async () => {
    if (viewState.selectedId) {
      const success = await deleteLicence(viewState.selectedId);
      if (success) {
        setViewState(prev => ({ ...prev, promptDialogVisible: false }));
      }
    }
  };

  const handleNavigateToEdit = (id?: string) => {
    navigate(`/licences/edit${id ? `/${id}` : ''}`);
  };

  const callbackMapping = (dtoData: LicenceListItemResponse[]): LicenceList[] => {
    return mapper.mapArray<LicenceListItemResponse, LicenceList, IAppContext>(dtoData, LicenceList, LicenceListItemResponse, { extraArguments: context });
  };

  return (
    <div className="mdlMain">
      <div className="mdlToolbar">
        <Toolbar>
          <ToolbarItem>
            <Button
              icon="plus"
              fillMode="outline"
              onClick={() => {
                notifications.showNotification('Zarejestruj konto firmowe z użyciem formularza rejestracji (strona główna aplikacji), a następnie przejdź do edycji utworzonej w ten sposób licencji.', NotificationEnum.Warning);
                return;
              }}
            >
              Dodaj
            </Button>
          </ToolbarItem>
        </Toolbar>
      </div>

      <div className="mdlDataView">
        <PaginatedDataGrid
          url={url}
          filterable={true}
          filter={viewState.filter}
          sortable={true}
          sort={viewState.sort}
          callbackMapping={callbackMapping}
        >
          <Column field="officeName" title="Nazwa klienta" filter="text" />
          <Column field="workersLimit" title="Limit pracowników" filterable={false} />
          <Column field="validFrom" title="Licencja od" format="{0:d}" filter="date" filterable={false} />
          <Column field="validTo" title="Licencja do" format="{0:d}" filter="date" filterable={false} />
          <Column field="promoCode" title="PromoCode" filterable={false} sortable={false} />
          <Column field="lastOfficeUserLoginDate" title="Ost. logowanie" format="{0:d}" filter="date" filterable={false} width="100px" />
          <Column
            field="isValid"
            title="Aktywne"
            filter="boolean"
            width="100px"
            cell={(d: GridCellProps) => (
              <td>{(d.dataItem as LicenceList).isValid ? "+" : "-"}</td>
            )}
          />
          <Column
            title="Moduły"
            filterable={false}
            sortable={false}
            width="100px"
            cell={(props) => (
              <GridModulesCell
                dataItem={props.dataItem}
              />
            )}
          />
          <Column
            title="Operacje"
            filterable={false}
            sortable={false}
            width="100px"
            cell={(props) => (
              <GridOperationsCell
                dataItem={props.dataItem}
                onEdit={handleNavigateToEdit}
                onDelete={handleDeleteButtonClick}
              />
            )}
          />
        </PaginatedDataGrid>
      </div>

      {viewState.promptDialogVisible && (
        <PromptDialog
          text={`<p>UWAGA !!! Czy na pewno chcesz usunąć licencję dla <b>"${viewState.selectedName}"</b> ?</p>
                Operacja ta spowoduje zablokowanie możliwości korzystania z aplikacji.`}
          onYes={handleDeleteConfirm}
          onNo={() => setViewState(prev => ({ ...prev, promptDialogVisible: false }))}
        />
      )}

      {viewState.motivationDialogVisible && (
        <MotivationDialog
          onClose={() => setViewState(prev => ({ ...prev, motivationDialogVisible: false }))}
        />
      )}
    </div>
  );
};