import React, { useContext, useEffect } from 'react';

import { QRCode } from '@progress/kendo-react-barcodes';

import TodayInformation from '../../models/local/Dashboard/TodayInformation';
import { AppContext, IAppContext } from '../../services/AppContext';

const QrCodeBox = (props: {}) => {
  const context = useContext<IAppContext>(AppContext);

  useEffect(() => {
  }, []);

  return (
    <div
      id="Quote"
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
      className="brickframe"
    >
      <div style={{
        display: "flex",
        color: "#000",
        padding: "15px",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <QRCode
          value={context.values.specialId}
          errorCorrection="H"
          size={250}
          renderAs='canvas'
          encoding='UTF_8'
        />
      </div>
    </div>
  );
};

export default QrCodeBox;