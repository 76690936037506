export const USER_POST_useActivationCode = "User/status/activate/useActivationCode";
export const USER_PUT_resendActivationEmail = (id: any) => `User/${id}/resendActivationEmail`;
export const USER_GET = "User";
export const USER_GET_byId = (id: any) => `User/${id}`;
export const USER_GET_bySpecialId = (id: any) => `User/specialId/${id}`;
export const USER_GET_newUser = () => `User/newUser`;
export const USER_PUT = "User";
export const USER_POST = "User";
export const USER_PUT_MoveToArchive = "User/movetoarchive";
export const USERS_ARCHIVED_GET = "User/archived";
export const USER_PUT_MoveToUsers = "User/movetousers";

export const USER_GET_all = "User/all";

export const SESSION_POST_login = "Session/login";

export const UTILS_POST_buildDetails = "Utils/buildDetails";
export const UTILS_GET_systemInfo = "Utils/systemInfo";

export const OFFICE_POST_register = "Office/register";

export const DICT_GET_all = "Dict/all";
export const DICT_GET_editable = "Dict/editable";
export const DICT_POST_ADD = "Dict";
export const DICT_PUT_EDIT = "Dict";
export const DICT_DELETE = (id: any) => `Dict/${id}`;

export const LICENCE_GET_BY_ID = (id: any) => `Admin/licences/${id}`;
export const LICENCE_GET = "Admin/licences/list";
export const LICENCE_PUT = `Admin/licences`;
export const LICENCE_POST = "Admin/licences";
export const LICENCE_DELETE = (id: any) => `Admin/licences/${id}`;

export const PROMOCODE_GET_BY_ID = (id: any) => `PromoCode/${id}`;
export const PROMOCODE_GET = "PromoCode/list";
export const PROMOCODE_PUT = `PromoCode`;
export const PROMOCODE_POST = "PromoCode";
export const PROMOCODE_DELETE = (id: any) => `PromoCode/${id}`;

export const CLIENTLICENCE_GET = `ClientLicence`;

export const EXPORT_Enova365 = "Export/enova365Leaves";
export const EXPORT_ERPOptima = "Export/erpoptima";
export const EXPORT_WFGang = "Export/wfgang";
export const EXPORT_Symfonia = "Export/symfonia";

export const LEAVE_POST = "Leave";
export const LEAVE_GET_own = (year: number) => `Leave/own/${year}`;
export const LEAVE_GET_all = (year: number, month?: number | null) => {
    if (month !== null && month !== undefined) {
        return `Leave/all/${year}/${month}`;
    }
    return `Leave/all/${year}`;
};
export const LEAVE_GET_forConsideration = "Leave/forConsideration";
export const LEAVE_GET_considered = (year: any) => `Leave/considered/${year}`;
export const LEAVE_GETBYID = (id: any) => `Leave/${id}`;
export const LEAVE_PUT_changeStatus = "Leave/changeStatus";
export const LEAVE_GET_replacementUsers = (id?: string) => `Leave/replacementUsers?userId=${id}`;
export const LEAVE_GET_monthView = "Leave/monthView";
export const LEAVE_GET_exportData = "Leave/exportData";
export const LEAVE_GET_subordinateUsers = "Leave/subordinateUsers";
export const LEAVE_GET_dashboardPlannedLeaves = "Leave/dashboardPlannedLeaves";
export const LEAVE_GET_dashboardActualLeaves = "Leave/dashboardActualLeaves";
export const LEAVE_GET_dashboardForConsideration = "Leave/dashboardForConsideration";
export const LEAVE_POST_calculateApp = "Leave/calculateApp";
export const LEAVE_GET_getUserLeaveStats = (id: string, year: number): string => `Leave/getUserLeaveStats/${id}/${year}`;
export const LEAVE_GET_yearlyReport = (year: any) => `Leave/yearlyReport/${year}`;

export const LIMITS_GET_templates = "LimitTypes/templates";
export const LIMITS_GET_forOffice = (id: number) => `LimitTypes/forOffice/${id}`;
export const LIMITS_PUT_editOfficeLimits = "LimitTypes/editOfficeLimits";
export const LIMITS_PUT_editUserLimits = "LimitTypes/editUserLimits";
export const LIMITS_POST_initializeYear = "LimitTypes/initializeYear";

export const LIMITS_GET_allForCurrentUserDDI = (year: number): string => `LimitTypes/allForCurrentUserDDI/${year}`;
export const LIMITS_GET_allForUserDDI = (id: string, year: number): string => `LimitTypes/allForUserDDI/${id}/${year}`;
export const LIMITS_GET_allForUser = (id: string, year: number): string => `LimitTypes/allForUser/${id}/${year}`;

export const STORAGE_UPLOAD_contract = "Storage/uploadContract";
export const STORAGE_UPLOAD_competency = "Storage/uploadCompetency";
export const STORAGE_UPLOAD_doc = "Storage/uploadDoc";
export const STORAGE_UPLOAD_medical = "Storage/uploadMedical";
export const STORAGE_UPLOAD_notification = "Storage/uploadNotification";
export const STORAGE_UPLOAD_resource = "Storage/uploadResource";
export const STORAGE_UPLOAD_training = "Storage/uploadTraining";
export const STORAGE_UPLOAD_workExperience = "Storage/uploadWorkExperience";
export const STORAGE_UPLOAD_note = "Storage/uploadNote";
export const STORAGE_UPLOAD_avatar = "Storage/uploadAvatar";
export const STORAGE_REMOVE = "Storage/removeFile";
export const STORAGE_DOWNLOAD = `Storage/downloadFile`;
export const STORAGE_GET_avatar = (id: any) => `Storage/getAvatar/${id}`;
export const STORAGE_GET_avatarBase64 = (id: any) => `Storage/getAvatarBase64/${id}`;

export const DEPARTMENTS_POST = "Department";
export const DEPARTMENTS_PUT = "Department";
export const DEPARTMENTS_GET = "Department";
export const DEPARTMENTS_DELETE = (id: any) => `Department/${id}`;
export const DEPARTMENTS_PUT_users_assign = "Department";
export const DEPARTMENTS_GET_users = (id: any) => `Department/${id}/users`;
export const DEPARTMENTS_GET_usersAssignedUnassigned = (id: any) => `Department/${id}/users/assignedUnassigned`;
export const DEPARTMENTS_PUT_users_assignUsers = "Department/users/assignUsers";

export const DASHBOARD_GET = `Dashboard`;

export const PROFILE_POST_passwordresetlink = "Profile/passwordresetlink";
export const PROFILE_POST_passwordchange = "Profile/passwordchange";
export const PROFILE_POST_password = "Profile/password";
export const PROFILE_GET_info = "Profile/userinfo";
export const PROFILE_POST_avatar = "Profile/changeAvatar";

export const CALENDAR_GET_calendars = "Calendar/calendars";
export const CALENDAR_POST_calendar = "Calendar";
export const CALENDAR_PUT_calendar = "Calendar";
export const CALENDAR_DELETE_calendar = "Calendar";
export const CALENDAR_GET_Id_items = (id: any, year: any) => `Calendar/${id}/${year}/items`;
export const CALENDAR_DELETE_item_Id = (id: any) => `Calendar/item/${id}`;
export const CALENDAR_POST_item = "Calendar/item";
export const CALENDAR_GET_calendars_users = (calendarId: any) => `Calendar/calendars/${calendarId}/users`;
export const CALENDAR_PUT_users_calendar = "Calendar/users/calendar";
export const CALENDAR_PUT_restoreDefaultHolidays_Id = (id: any) => `Calendar/restoreDefaultHolidays/${id}`;
export const CALENDAR_PUT_batchAddDaysOff = `Calendar/batchAddDaysOff`;
export const CALENDAR_PUT_batchRemoveDaysOff = `Calendar/batchRemoveDaysOff`;

export const BRIEFCASES_GET_COMPETENCIES = "Briefcases/competencies";
export const BRIEFCASES_GET_CONTRACTS = "Briefcases/contracts";
export const BRIEFCASES_GET_DOCS = "Briefcases/docs";
export const BRIEFCASES_GET_MEDICALS = "Briefcases/medicals";
export const BRIEFCASES_GET_NOTIFICATIONS = "Briefcases/notifications";
export const BRIEFCASES_GET_RESOURCES = "Briefcases/resources";
export const BRIEFCASES_GET_TRAININGS = "Briefcases/trainings";
export const BRIEFCASES_GET_WORKEXPRESIENCES = "Briefcases/workexperiences";

export const BRIEFCASES_POST_ADDCONTRACT = "Briefcases/addcontract";
export const BRIEFCASES_POST_ADDCOMPETENCY = "Briefcases/addcompetency";
export const BRIEFCASES_POST_ADDDOC = "Briefcases/adddoc";
export const BRIEFCASES_POST_ADDMEDICAL = "Briefcases/addmedical";
export const BRIEFCASES_POST_ADDNOTIFICATION = "Briefcases/addnotification";
export const BRIEFCASES_POST_ADDRESOURCE = "Briefcases/addresource";
export const BRIEFCASES_POST_ADDTRAINING = "Briefcases/addtraining";
export const BRIEFCASES_POST_ADDWORKEXPERIENCE = "Briefcases/addworkexperience";

export const SETTINGS_GET = "Settings";
export const SETTINGS_PUT = "Settings";

export const DROPDOWN_GET_ALLUSERS = "Dropdown/allUsers";
export const DROPDOWN_GET_ALLOWEDDEPARTMENTSTREE = "Dropdown/allowedDepartmentsTree";
export const DROPDOWN_GET_ALLOFFICES = "Dropdown/allOffices";
export const DROPDOWN_GET_USERS = (id: string | undefined) => id !== undefined ? `Dropdown/departmentUsers/${id}` : 'Dropdown/departmentUsers';

export const WORKPLANS_GET_WORKPLANS = "WorkPlans";
export const WORKPLANS_GET_WORKPLAN = (id: any) => `WorkPlans/${id}`;
export const WORKPLANS_PUT_WORKPLANS = "WorkPlans";
export const WORKPLANS_POST_WORKPLANS = "WorkPlans";
export const WORKPLANS_DELETE_WORKPLANS = (id: any) => `WorkPlans/${id}`;


export const WORKTIME_POST_REGISTER = "WorkTime/register";
export const WORKTIME_GET_FORCURRENTUSER = (startDate: string, endDate: string) => `WorkTime/forCurrentUser?startDate=${startDate}&endDate=${endDate}`;
export const WORKTIME_GET_FORUSER = (userId: string, startDate: string, endDate: string) => `WorkTime/forUser/${userId}?startDate=${startDate}&endDate=${endDate}`;
export const WORKTIME_GET_DEPARTMENT = (departmentId: string | null, startDate: string, endDate: string) => `WorkTime/department?startDate=${startDate}&endDate=${endDate}${departmentId ? `&departmentId=${departmentId}` : ""}`;
export const WORKTIME_DELETE_WORKPLANS = (id: any) => `WorkTime/${id}`;
export const WORKTIME_POST_REGISTERONBEHALF = "WorkTime/registerOnBehalf";
export const WORKTIME_POST_REGISTERBYQRCODE = "WorkTime/registerByQRCode";
export const WORKTIME_POST_UPDATEDESCRIPTION = "WorkTime/updateDescription";
