import React from 'react';



import { PROMOCODE_GET } from '../api/apiEndpoints';
import PromoCodesList from '../components/PromoCode/PromoCodesList';

const PromoCodes = () => {
  return (
    <div className="mdlRoot">
      <PromoCodesList url={PROMOCODE_GET} />
    </div>
  );
}

export default PromoCodes;