import React from 'react';

import EmployeeQRView from '../components/QrCodesManager/EmployeeQRView';

const QrCodesManagerPage = () => {
  return (
    <div className="mdlRoot">
      <EmployeeQRView />
    </div>
  );
}

export default QrCodesManagerPage;