import React from 'react';

import {
    CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor
} from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridColumn as Column } from '@progress/kendo-react-grid';

import { LeaveStatusEnumTranslation } from '../../../models/enums/LeaveStatusEnum';
import DropdownItem from '../../../models/local/Dropdown/DropdownItem';
import LeaveListItem from '../../../models/local/Leaves/LeaveListItem';

interface LeavesExcelExporterProps {
    data: LeaveListItem[] | undefined;
    filter: CompositeFilterDescriptor | undefined;
    sort: Array<SortDescriptor>;
    exporterRef: (exporter: ExcelExport) => void;
}

/**
 * Component responsible for exporting leave applications to Excel
 */
const LeavesExcelExporter = ({ data, filter, sort, exporterRef }: LeavesExcelExporterProps) => {
    // Function preparing data for Excel export
    const prepareExcelData = () => {
        if (!data) return [];

        // Mapping data with formatting for Excel
        return orderBy(filterBy(data, filter!), sort).map((item: LeaveListItem) => {
            const formattedStartDate = item.startDate ? new Date(item.startDate).toLocaleDateString('pl-PL') : '';
            const formattedEndDate = item.endDate ? new Date(item.endDate).toLocaleDateString('pl-PL') : '';

            return {
                "Nr wniosku": item.id.substr(0, 8),
                "Nieobecność": item.limitType,
                "Dotyczy": item.ownerName,
                "Zastępstwo": item.replacementUsers.length > 0
                    ? item.replacementUsers.map((u: DropdownItem) => u.text).join(", ")
                    : "(brak)",
                "Rozpoczęcie": formattedStartDate,
                "Zakończenie": formattedEndDate,
                "Ilość dni": item.calculations?.workingDaysSum !== undefined
                    ? item.calculations.workingDaysSum.toString()
                    : "-",
                "Status": LeaveStatusEnumTranslation(item.status)
            };
        });
    };

    // Generate filename with current date
    const getFileName = () => {
        return `WnioskiUrlopowe_${new Date().toISOString().slice(0, 10)}.xlsx`;
    };

    if (!data) return null;

    return (
        <ExcelExport
            ref={exporterRef}
            data={prepareExcelData()}
            fileName={getFileName()}
            filterable={true}
        >
            <Column field="Nr wniosku" title="Nr wniosku" width={100} />
            <Column field="Nieobecność" title="Nieobecność" width={180} />
            <Column field="Dotyczy" title="Dotyczy" width={180} />
            <Column field="Zastępstwo" title="Zastępstwo" width={200} />
            <Column field="Rozpoczęcie" title="Rozpoczęcie" width={110} />
            <Column field="Zakończenie" title="Zakończenie" width={110} />
            <Column field="Ilość dni" title="Ilość dni" width={70} />
            <Column field="Status" title="Status" width={140} />
        </ExcelExport>
    );
};

export default LeavesExcelExporter;