import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import { apiClient } from '../../../api/apiClient';
import { LEAVE_GETBYID, LEAVE_PUT_changeStatus } from '../../../api/apiEndpoints';
import LeaveDetailsResponseDto from '../../../models/dto/Leaves/Responses/LeaveDetailsResponseDto';
import LeaveStatusEnum, { LeaveStatusEnumTranslation } from '../../../models/enums/LeaveStatusEnum';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import UserRoleEnum from '../../../models/enums/UserRoleEnum';
import LeaveDetails from '../../../models/local/Leaves/LeaveDetails';
import LeaveHistory from '../../../models/local/Leaves/LeaveHistory';
import StatusChangeOut from '../../../models/local/Leaves/StatusChangeOut';
import { mapper } from '../../../models/mapper';
import { AppContext, IAppContext } from '../../../services/AppContext';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';
import PromptDialog from '../../Common/PromptDialog';
import {
  FormDatePicker, FormInput, FormMultiSelect, FormTextArea
} from '../../Kendo/form-components';
import AddAppRangeDetails from '../AddApplication/AddAppRangeDetails';
import ChangeStatusDialog from '../ApplicationsList/ChangeStatusDialog';

type AppIdParams = {
  id: string;
};

const RequestDetail = () => {
  const loader = useAppLoader(true);
  const [selected, setSelected] = React.useState(0);
  const [leave, setLeave] = React.useState<LeaveDetails>();
  const [promptDialogVisible, setPromptDialogVisible] = React.useState<boolean>(false);
  const [promptChangeStatusDialogVisible, setChangeStatusDialogVisible] = React.useState<boolean>(false);
  const notifications = useAppNotifications();

  const appId = useParams<AppIdParams>()?.id;
  const navigate = useNavigate();
  const context = React.useContext<IAppContext>(AppContext);

  const loadData = () => {
    loader.showLoading(true);
    apiClient({
      method: "GET",
      url: LEAVE_GETBYID(appId),
      data: {},
    }).then((resp) => {

      const mappedResult: LeaveDetails = mapper.map(resp?.data?.result, LeaveDetails, LeaveDetailsResponseDto);

      setLeave(mappedResult);
      loader.showLoading(false);
    });
  }

  const changeStatus = (statusChange: StatusChangeOut) => {
    const changeStatusAsync = async (newStatus: LeaveStatusEnum) => {
      loader.showLoading(true);
      apiClient({
        method: "PUT",
        url: LEAVE_PUT_changeStatus,
        data: {
          id: leave?.id,
          status: newStatus
        }
      }).then((resp) => {
        loader.showLoading(false);
        if (resp.status !== 200) {
          notifications.showNotification(resp.data.errors, NotificationEnum.Error);
          return;
        }
        loadData();
        notifications.showNotification('Status został zmieniony', NotificationEnum.Success);

        setChangeStatusDialogVisible(false);
      });
    };
    changeStatusAsync(statusChange.newStatus.id as LeaveStatusEnum);
  }

  React.useEffect(() => {
    loadData();
  }, []);

  const withdrawnApplication = () => {
    loader.showLoading(true);
    apiClient({
      method: "PUT",
      url: LEAVE_PUT_changeStatus,
      data: {
        id: leave?.id,
        status: LeaveStatusEnum.WITHDRAWN
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        return;
      }
      notifications.showNotification("Wniosek został wycofany", NotificationEnum.Success);
      navigate(-1);
    });
  };

  return (
    <div className="fullPageContentWrapper">
      {!loader.isLoading() && <>
        <div className="fullWidthColumn">
          <div className="columnsWrapper">
            <div className="leftColumn">

              <TabStrip selected={0}>
                <TabStripTab title="Informacje o wniosku">

                  {promptDialogVisible === true && (
                    <PromptDialog
                      text="Czy na pewno chcesz wycofać złożony wniosek ?"
                      onYes={() => { withdrawnApplication(); }}
                      onNo={() => { setPromptDialogVisible(false) }}
                    />
                  )}

                  <Toolbar style={{ width: "100%" }}>
                    <ToolbarItem>
                      <Button
                        icon="undo"
                        fillMode="outline"
                        onClick={() => { navigate(-1) }}>
                        Powrót
                      </Button>
                    </ToolbarItem>

                    <ToolbarSeparator />

                    {leave?.status === LeaveStatusEnum.AWAITING && leave?.isLeaveBeConsideredByUser &&
                      <ToolbarItem>
                        <Button
                          icon="trash"
                          themeColor="error"
                          fillMode="outline"
                          onClick={() => { setPromptDialogVisible(true); }}>
                          Wycofaj wniosek
                        </Button>
                      </ToolbarItem>
                    }

                    {context.values.role === UserRoleEnum.HR &&
                      <ToolbarItem>
                        <Button
                          icon="trash"
                          themeColor="error"
                          fillMode="outline"
                          onClick={() => { setChangeStatusDialogVisible(true); }}>
                          Zmień status
                        </Button>
                      </ToolbarItem>
                    }

                  </Toolbar>

                  {promptChangeStatusDialogVisible === true && (
                    <ChangeStatusDialog
                      onChange={(statusChange: StatusChangeOut) => { changeStatus(statusChange) }}
                      onCancel={() => { setChangeStatusDialogVisible(false); }}
                      statusChange={{ oldStatus: leave?.status, newStatus: LeaveStatusEnum.REFUSED }}
                    />
                  )}

                  <br />

                  <Form
                    onSubmit={() => { }}
                    initialValues={leave}
                    render={(formRenderProps: FormRenderProps) => (
                      <FormElement
                        style={{
                          width: "100%"
                        }}
                      >
                        <span>Aktualny status wniosku: <b>{LeaveStatusEnumTranslation(leave?.status)}</b></span>
                        {/* LeaveStatusEnumTranslation(leave.status).toUpperCase() */}

                        <Field
                          id={"ownerName"}
                          name={"ownerName"}
                          label={"Dotyczy"}
                          component={FormInput}
                          disabled={true}
                        />

                        <Field
                          id={"applicantUserName"}
                          name={"applicantUserName"}
                          label={"Składający wniosek"}
                          component={FormInput}
                          disabled={true}
                        />

                        <Field
                          id={"limitType"}
                          name={"limitType"}
                          label={"Nieobecność"}
                          component={FormInput}
                          disabled={true}
                        />

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="k-form-field" style={{ width: "80%" }}>
                            <Field
                              id={"startDate"}
                              name={"startDate"}
                              label={"Data rozpoczęcia"}
                              component={FormDatePicker}
                              disabled={true}
                            />
                          </div>

                          <div className="k-form-field" style={{ width: "80%" }}>
                            <Field
                              id={"endDate"}
                              name={"endDate"}
                              label={"Data zakończenia"}
                              component={FormDatePicker}
                              disabled={true}
                            />
                          </div>
                        </div>

                        <Field
                          id={"replacementUsers"}
                          name={"replacementUsers"}
                          label={"Osoby zastępujące podczas nieobecności"}
                          textField="text"
                          component={FormMultiSelect}
                          data={leave?.replacementUsers}
                          dataItemKey="id"
                          disabled={true}
                        />

                        <Field
                          id={"comment"}
                          name={"comment"}
                          label={"Komentarz składającego wniosek"}
                          component={FormTextArea}
                          disabled={true}
                        />

                        <Field
                          id={"hrComment"}
                          name={"hrComment"}
                          label={"Komentarz działu kadr"}
                          component={FormTextArea}
                          disabled={true}
                        />

                      </FormElement>

                    )}
                  />
                </TabStripTab>
              </TabStrip>
            </div>

            <div className="rightColumn">
              <TabStrip selected={selected} onSelect={(e) => setSelected(e.selected)} >
                <TabStripTab title="Szczegóły złożonego wniosku">
                  <AddAppRangeDetails items={leave!.calculations!.dates}></AddAppRangeDetails>
                </TabStripTab>
                <TabStripTab title="Historia wniosku">
                  <Grid data={leave?.leavesHistory}>
                    <Column field="date" filter="date" title="Data zmiany" format="{0:d}" />
                    <Column field="status" title="Status" cell={(d: GridCellProps) => (<td>{LeaveStatusEnumTranslation(d.dataItem.status)}</td>)} />
                    <Column field="firstName" title="Operator" cell={(d: GridCellProps) => (<td>{`${(d.dataItem as LeaveHistory).firstName} ${(d.dataItem as LeaveHistory).lastName} `}</td>)} />
                  </Grid>
                </TabStripTab>
              </TabStrip>
            </div>
          </div>
        </div>

      </>}
    </div >
  );
}

export default RequestDetail;