import React from 'react';

import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@progress/kendo-react-tooltip';

interface InfoTooltipProps {
    title: string;
    position?: 'top' | 'right' | 'bottom' | 'left';
}

/**
 * A reusable tooltip component that shows an info icon with hover tooltip
 */
const InfoTooltip = ({
    title,
    position = 'right'
}: InfoTooltipProps) => {
    const renderContent = () => (
        <span>  <FontAwesomeIcon
            icon={faQuoteLeft}
            className="fa-xl" />
            <br />
            <br />
            <span dangerouslySetInnerHTML={{ __html: title }} ></span>
        </span>
    );

    return (<>


        <Tooltip
            position={position}
            tooltipStyle={{ backgroundColor: '#292351', padding: '15px', borderRadius: '15px', boxShadow: '0px 0px 30px gray', color: "white" }}
            content={renderContent}
            openDelay={0.3}
        >
            <div title={title}>
                <span className="k-icon k-i-information" style={{ pointerEvents: "none" }} />
            </div>
        </Tooltip>
    </>
    );
};

export default InfoTooltip;