import React from 'react';

import { Tooltip } from '@progress/kendo-react-tooltip';

interface ProgressBarProps {
    requested: number;
    predicted: number;
    max: number | null;
}

const borderRadius = "8px";

const CustomProgressBar = ({ requested, predicted, max }: ProgressBarProps) => {
    max = max ?? requested + predicted;

    const valuePercentage = (requested / max!) * 100;
    const predictedPercentage = (predicted / max!) * 100;

    return (
        <Tooltip
            anchorElement="target"
            position="bottom"
            openDelay={0}
            tooltipStyle={{ backgroundColor: "white", color: "purple", border: "1px solid purple" }}
        >
            {requested + predicted > max! &&
                <div style={{ color: "red", fontWeight: "bold" }}><span className="k-icon k-i-error" /> Wniosek przekracza dostępny limit wskazanego rodzaju nieobecności.</div>
            }
            <div style={{
                display: 'flex',
                height: "22px",
                width: '100%',
                borderRadius: borderRadius,
                marginTop: "6px",
                float: "left",
                background: "linear-gradient(to left, rgba(255, 0, 0, 0.2) 65%, orange)",
                padding: "1px",
                boxShadow: "0px 0px 20px rgba(66, 68, 90, 1)"
            }}>

                <div style={{
                    display: 'flex',
                    width: '100%',
                    borderRadius: borderRadius,
                    float: "left",
                    background: "linear-gradient(to bottom, #fff 50%,#ebebeb 60% )",
                    padding: "1px"
                }}>

                    <div style={{ width: `${valuePercentage}%`, background: "linear-gradient(150deg, #1c1c42 65%, purple)", borderRadius: borderRadius, opacity: 0.85 }}>
                        <div style={{ textAlign: "center", paddingRight: "8px" }} title="Ilość wcześniej wykorzystanych dni" >
                            {requested}
                        </div>
                    </div>

                    {requested + predicted <= max! &&
                        <div style={{ boxShadow: "0px 0px 20px purple", width: `${predictedPercentage}%`, background: "linear-gradient(150deg, purple 45%, #1c1c42)", borderRadius: borderRadius, animation: "blinker 5s infinite" }}>
                            <div style={{ textAlign: "center", paddingRight: "8px", color: "#fff", fontWeight: "bold", textShadow: "0px 0px 2px #000" }} title="Ilość dni wykorzystanych przez składany wniosek">
                                +{predicted}
                            </div>
                        </div >
                    }

                    {
                        requested + predicted > max! &&
                        <div style={{ boxShadow: "0px 0px 20px red", width: `${predictedPercentage}%`, backgroundImage: 'linear-gradient(45deg, #fcc5cf 25%, #f0f0f0 25%, #f0f0f0 50%, #fcc5cf 50%, #fcc5cf 75%, #f0f0f0 75%, #f0f0f0 100%)', backgroundSize: "10px 10px", borderRadius: borderRadius, zIndex: 100 }}>
                            <div style={{ textAlign: "right", paddingRight: "8px", color: "black", fontWeight: "bold" }} title="Brakująca ilość dni">
                                - {requested + predicted - max!}
                            </div>
                        </div>
                    }

                    <div style={{ position: 'absolute', right: '40px', color: 'white', fontWeight: 'bolder', borderRadius: borderRadius, textShadow: "1px 0px 2px black" }} title="Łączna ilość dni">
                        {max!}
                    </div>
                </div>
            </div >
        </Tooltip >
    );
};

export default CustomProgressBar;
