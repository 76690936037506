import React from 'react';



import ClientLicenceInfo from '../components/ClientLicence/ClientLicenceInfo';

const ClientLicenceView = () => {
  return (
    <>
      <div className="fullPageContentWrapper">
        <div className="fullWidthColumn">
          <ClientLicenceInfo></ClientLicenceInfo>
        </div>
      </div >
    </>
  )
}

export default ClientLicenceView;