import * as Permissions from '../../permissions/permissions';

export enum MenuItemType {
    Basic,
    Nested,
    Separator,
    Avatar
};

export interface IMenuItem {
    name?: string;

    id?: number;
    parentid?: number;

    permission?: Permissions.Scopes;

    showInMobile: boolean;

    icon?: string;
    route?: string;

    menuItemType?: MenuItemType;
    avatar?: boolean; // special element

    separator?: boolean; // special element - space between items
    separatorLabel?: string; // separator text-label

    selected?: boolean; // highlight
    expanded?: boolean;
    hidden?: boolean; // render or not
}

export const MenuItems: IMenuItem[] = [
    {
        name: "UserInfo",
        avatar: true,
        showInMobile: true,
    },
    {
        name: "Pulpit",
        icon: "k-i-grid",
        selected: true,
        route: "/",
        permission: Permissions.Scopes.Dashboard,
        showInMobile: true,
    },
    {
        name: "Licencje",
        icon: "k-i-grid",
        selected: true,
        route: "/",
        permission: Permissions.Scopes.Licences,
        showInMobile: false,
    },
    {
        name: "Kody promocyjne",
        icon: "k-i-grid",
        selected: false,
        route: "/promocodes/list",
        permission: Permissions.Scopes.PromoCodes,
        showInMobile: false,
    },
    { separator: true, separatorLabel: "Kartoteki", permission: Permissions.Scopes.Briefcases, showInMobile: false, },
    {
        name: "Struktura firmy",
        icon: "k-i-categorize",
        route: "/departments",
        permission: Permissions.Scopes.Departments,
        showInMobile: false,
    },
    {
        name: "Pracownicy",
        icon: "k-i-folder-more",
        route: "/employees",
        permission: Permissions.Scopes.Employees,
        showInMobile: false,
    },
    {
        name: "Dokumenty ▼",
        id: 100,
        icon: "k-i-tell-a-friend",
        route: "/briefcases/contracts",
        permission: Permissions.Scopes.Bc,
        showInMobile: false,
    },
    {
        name: "Umowy",
        id: 101,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/contracts",
        permission: Permissions.Scopes.Bc,
        showInMobile: false,
    },
    {
        name: "Badania",
        id: 102,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/medicals",
        permission: Permissions.Scopes.Bc,
        showInMobile: false,
    },
    {
        id: 103,
        parentid: 100,
        hidden: false,

        name: "Szkolenia",
        icon: "k-i-arrow-60-right",
        route: "/briefcases/trainings",
        permission: Permissions.Scopes.Bc,
        showInMobile: false,
    },
    {
        name: "Powiadomienia",
        id: 104,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/notifications",
        permission: Permissions.Scopes.Bc,
        showInMobile: false,
    },
    {
        name: "Doświadczenie",
        id: 105,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/workexperience",
        permission: Permissions.Scopes.Bc,
        showInMobile: false,
    },
    {
        name: "Kompetencje",
        id: 106,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/competencies",
        permission: Permissions.Scopes.Bc,
        showInMobile: false,
    },
    {
        name: "Zasoby",
        id: 107,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/resources",
        permission: Permissions.Scopes.Bc,
        showInMobile: false,
    },
    {
        name: "Dokumenty",
        id: 108,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/briefcases/docs",
        permission: Permissions.Scopes.Bc,
        showInMobile: false,
    },
    {
        name: "Identyfikatory QR",
        id: 109,
        parentid: 100,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/qrCodesManager",
        permission: Permissions.Scopes.QrCodesManager,
        showInMobile: false,
    },  
    { separator: true, separatorLabel: "Urlopy", permission: Permissions.Scopes.AddApp, showInMobile: true, },
    {
        name: "Złóż wniosek",
        icon: "k-i-plus-circle",
        route: "/addapp",
        permission: Permissions.Scopes.AddApp,
        showInMobile: true,
    },
    {
        name: "Twoje limity urlopu",
        icon: "k-i-information",
        route: "/userlimits",
        permission: Permissions.Scopes.UserLimits,
        showInMobile: true,
    },
    {
        name: "Stan urlopów",
        icon: "k-i-calendar",
        route: "/leavesstatesreport",
        permission: Permissions.Scopes.LeavesStatesReport,
        showInMobile: false,
    },
    {
        name: "Lista wniosków",
        icon: "k-i-track-changes-enable",
        route: "/appslist",
        permission: Permissions.Scopes.AppsList,
        showInMobile: true,
    },
    {
        name: "Kalendarz urlopów",
        icon: "k-i-calendar",
        route: "/calendar",
        permission: Permissions.Scopes.Calendar,
        showInMobile: true,
    },
    { separator: true, separatorLabel: "Rejestracja pracy", permission: Permissions.Scopes.WorkTime, showInMobile: true, },
    {
        name: "Rejestracja godzin",
        icon: "k-i-clock",
        route: "/worktime",
        permission: Permissions.Scopes.WorkTimeRegister,
        showInMobile: true,
    },
    {
        name: "Karty pracy",
        icon: "k-i-table-align-middle-center",
        route: "/worktimeBrowse",
        permission: Permissions.Scopes.WorkTimeBrowse,
        showInMobile: true,
    },
    // {
    //     name: "GPS",
    //     icon: "k-i-clock",
    //     route: "/gpstest",
    //     permission: Permissions.Scopes.WorkTimeGPSTest
    // },
    { separator: true, separatorLabel: "Ustawienia", permission: Permissions.Scopes.Settings, showInMobile: false, },
    {
        name: "Parametry ▼",
        id: 200,
        icon: "k-i-gears",
        route: "/settingsTypesOfLeaves",
        permission: Permissions.Scopes.Settings,
        showInMobile: false,
    },
    {
        name: "Limity urlopowe",
        id: 201,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsTypesOfLeaves",
        permission: Permissions.Scopes.Settings,
        showInMobile: false,
    },
    {
        name: "Kalendarze pracy",
        id: 202,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsCalendars",
        permission: Permissions.Scopes.Settings,
        showInMobile: false,
    },
    {
        name: "Plany pracy",
        id: 203,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/workPlans",
        permission: Permissions.Scopes.Settings,
        showInMobile: false,
    },
    {
        name: "Parametry",
        id: 204,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsParameters",
        permission: Permissions.Scopes.Settings,
        showInMobile: false,
    },
    {
        name: "Słowniki",
        id: 205,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsDictionaries",
        permission: Permissions.Scopes.Settings,
        showInMobile: false,
    },
    {
        name: "Licencja",
        id: 206,
        parentid: 200,
        hidden: false,
        icon: "k-i-arrow-60-right",
        route: "/settingsLicence",
        permission: Permissions.Scopes.ClientLicence,
        showInMobile: false,
    },  
    {
        name: "Eksport danych",
        icon: "k-i-redo",
        route: "/exportdata",
        permission: Permissions.Scopes.ExportData,
        showInMobile: false,
    },
];