import React from 'react';

import {
  CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor
} from '@progress/kendo-data-query';
import { Button, Toolbar, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Grid, GridCellProps, GridColumn as Column, GridFilterOperators, GridRowProps,
  GridSortChangeEvent
} from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';

import LeaveStatusEnum, {
  LeaveStatusEnumToColor, LeaveStatusEnumTranslation
} from '../../../models/enums/LeaveStatusEnum';
import DropdownItem from '../../../models/local/Dropdown/DropdownItem';
import LeaveListItem from '../../../models/local/Leaves/LeaveListItem';
import YearSelector from '../../Common/YearSelector';
import LeavesExcelExporter from './LeavesExcelExporter';
import LeavesPdfExporter from './LeavesPdfExporter';

interface MonthOption {
  text: string;
  value: number | null;
}

const ApplicationGridTab = (props:
  {
    data: LeaveListItem[] | undefined,
    icon: string,
    buttonLabel: string,
    buttonAction: (id: string) => void,
    year: number,
    month?: number | null,
    yearChangedHandler: (year: number) => void,
    monthChangedHandler?: (month: number | null) => void,
    showYearSelector?: boolean,
    showMonthSelector?: boolean
  }) => {
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();

  const filterOperators: GridFilterOperators = {
    text: [{ text: "grid.filterContainsOperator", operator: "zawiera" }],
    numeric: [{ text: "grid.filterEqOperator", operator: "eq" }],
    date: [{ text: "grid.filterEqOperator", operator: "eq" }],
    boolean: [{ text: "grid.filterEqOperator", operator: "eq" }],
  };

  const initialSort: Array<SortDescriptor> = [
  ];
  const [sort, setSort] = React.useState(initialSort);

  // Months for dropdown
  const monthOptions: MonthOption[] = [
    { text: 'Wszystkie', value: null },
    { text: 'Styczeń', value: 1 },
    { text: 'Luty', value: 2 },
    { text: 'Marzec', value: 3 },
    { text: 'Kwiecień', value: 4 },
    { text: 'Maj', value: 5 },
    { text: 'Czerwiec', value: 6 },
    { text: 'Lipiec', value: 7 },
    { text: 'Sierpień', value: 8 },
    { text: 'Wrzesień', value: 9 },
    { text: 'Październik', value: 10 },
    { text: 'Listopad', value: 11 },
    { text: 'Grudzień', value: 12 }
  ];

  const handleMonthChange = (event: any) => {
    if (props.monthChangedHandler) {
      // In Kendo DropDownList control, event.value contains the selected object from monthOptions list
      let selectedValue: number | null = null;

      // Get the correct numeric value or null
      if (event && event.value !== undefined) {
        if (event.value === null) {
          selectedValue = null;
        } else if (typeof event.value === 'object' && event.value !== null && 'value' in event.value) {
          // If it's an object from the options list, get its value property
          selectedValue = event.value.value;
        } else if (typeof event.value === 'number' || event.value === null) {
          // If it's directly a number or null
          selectedValue = event.value;
        }
      }

      props.monthChangedHandler(selectedValue);
    }
  };

  let pdfExporter: GridPDFExport;
  let excelExporter: ExcelExport;

  const handleExportToPDF = () => {
    if (pdfExporter !== null) {
      pdfExporter.save();
    }
  };

  const exportToExcel = () => {
    if (excelExporter !== null) {
      excelExporter.save();
    }
  };

  const rowRender = (trElement: any, props: GridRowProps) => {
    const element = React.cloneElement(
      trElement,
      { style: { color: LeaveStatusEnumToColor(props.dataItem.status) }, },
      trElement.props.children
    );

    return element;
  }

  const gridContent = (isForPrint: boolean) => {
    return <Grid
      style={{ maxHeight: "30%" }}
      data={props?.data != null ? orderBy(filterBy(props.data!, filter!), sort) : undefined}
      rowRender={rowRender}
      filterable={true}
      filter={filter}
      onFilterChange={(e) => setFilter(e.filter)}
      sortable={true}
      sort={sort}
      onSortChange={(e: GridSortChangeEvent) => {
        setSort(e.sort);
      }}>
      <Column field="id" title="Nr wniosku" cell={(d: GridCellProps) => (<td>{d.dataItem.id.substr(0, 8)}</td>)} width="100px" />
      <Column field="limitType" title="Nieobecność" width="180px" />
      <Column field="applicantDepartmentsNames" title="Dział" />
      <Column field="ownerName" title="Dotyczy" />
      <Column field="replacement" title="Zastępstwo" cell={(d: GridCellProps) => (<td>{d.dataItem.replacementUsers.length > 0 ? d.dataItem.replacementUsers.map((u: DropdownItem) => u.text).join(", ") : "(brak)"}</td>)} filterable={false} />
      <Column field="startDate" title="Rozpoczęcie" format="{0:dd.MM.yyyy}" width="110px" filterable={false} />
      <Column field="endDate" title="Zakończenie" format="{0:dd.MM.yyyy}" width="110px" filterable={false} />
      <Column field="calculations.workingDaysSum" cell={(d: GridCellProps) => (<td>{(d.dataItem as LeaveListItem)?.calculations?.workingDaysSum ?? "-"}</td>)} title="Ilość dni" width="110px" filterable={false} />
      <Column field="accepted" title="Status" width="140px" cell={(d: GridCellProps) => (<td><b>{LeaveStatusEnumTranslation(d.dataItem.status)}</b></td>)} filterable={false} />
      {/* Operations column - only visible in UI, not in PDF export */}
      {!isForPrint && (
        <Column
          title="Operacje"
          width="110px"
          cell={(c: any) => (
            <td>
              <Button
                fillMode="outline"
                onClick={() => {
                  props.buttonAction(c.dataItem.id)
                }}
              >{props.buttonLabel}</Button>
            </td>
          )}
          filterable={false}
        />
      )}
    </Grid>
  }

  return (
    <>
      <div className="mainToolbar">
        <Toolbar>
          {props.showYearSelector !== false && (
            <ToolbarItem>
              <YearSelector
                initialYear={props.year}
                onValueChanged={(year) => {
                  props.yearChangedHandler(year);
                }}
                readonly={false}
                showLabel={true}
              />
            </ToolbarItem>
          )}
          {props.showMonthSelector && (
            <ToolbarItem>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <div style={{ marginRight: '5px' }}>Miesiąc:</div>
                <DropDownList
                  data={monthOptions}
                  textField="text"
                  dataItemKey="value"
                  value={monthOptions.find(item => item.value === props.month) || monthOptions[0]}
                  onChange={handleMonthChange}
                  style={{ width: '150px' }}
                />
              </div>
            </ToolbarItem>
          )}
          <ToolbarSpacer />
          <ToolbarItem>
            <Button
              fillMode="outline"
              icon="page-properties"
              onClick={handleExportToPDF}
            >
              PDF
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <Button
              fillMode="outline"
              icon="file-excel"
              onClick={exportToExcel}
            >
              Excel
            </Button>
          </ToolbarItem>
        </Toolbar>
      </div>

      {gridContent(false)}

      {/* PDF Export component extracted to a separate file */}
      <LeavesPdfExporter exporterRef={(exporter: GridPDFExport) => (pdfExporter = exporter)}>
        {gridContent(true)}
      </LeavesPdfExporter>

      {/* Excel Export component extracted to a separate file */}
      <LeavesExcelExporter
        data={props.data}
        filter={filter}
        sort={sort}
        exporterRef={(exporter: ExcelExport) => (excelExporter = exporter)}
      />
    </>
  );
};

export default ApplicationGridTab;