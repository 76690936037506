import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Fade } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';

import { apiClient } from '../api/apiClient';
import { USER_POST_useActivationCode } from '../api/apiEndpoints';
import PromoBanner from '../components/LoginRegister/PromoBanner';
import useAppLoader from '../services/AppLoader';
import useSessionWrapper from '../services/SessionWrapper';
import moduleStyle from '../styles/LoginRegister.module.scss';

const ErrorView = ({ message }: { message: string }) => {
  const navigate = useNavigate();

  return (
    <Fade>
      <p>
        Twoje konto zostało już wcześniej aktywowane.<br />
        <p>Zaloguj się do systemu podając swój login i hasło lub skorzystaj z formularza resetowania hasła.</p>
        <p>
          <Button onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}>
            Przejdź do strony logowania
          </Button>
        </p>
      </p>
    </Fade>
  );
};

const PleaseWaitView = () => {

  return (
    <div style={{ width: "400px" }}>
      <p className={moduleStyle.formHeader}>
        Proszę czekać...
        <p>
          Trwa aktywacja konta.
        </p>
      </p>
    </div>
  );
};

const ActivationForm = () => {
  const navigate = useNavigate();

  return (
    <div style={{ width: "400px" }}>
      <p className={moduleStyle.formHeader}>
        Dziękujemy.
        <p>
          Twoje konto jest już aktywne!
        </p>
      </p>

      <p>
        <Button
          themeColor="success"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          Kliknij tutaj, aby zalogować się do systemu
        </Button>
      </p>
    </div>
  );
};

const ActivateAccount = () => {
  const { activationCode } = useParams();
  const sessionWrapper = useSessionWrapper();
  const loader = useAppLoader(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccessNotificationVisible, setIsSuccessNotificationVisible] = useState(false);

  useEffect(() => {
    const updateContext = async () => {
      await sessionWrapper.clearLocalStorageExceptMobileSettings();
    }
    updateContext();
    handleDoAccountActivation();
  }, []);

  const handleDoAccountActivation = async () => {
    apiClient({
      method: "POST",
      url: USER_POST_useActivationCode,
      data: {
        ActivationCode: activationCode,
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        setErrorMessage(resp.data.errors);
        return;
      }
      setIsSuccessNotificationVisible(true);
    });
  };

  return (
    <div className={moduleStyle.pageWrapper}>
      {errorMessage !== null && (
        <p>
          <NotificationGroup>
            <Notification
              key="error"
              closable={false}
              onClose={() => setErrorMessage(null)}
              type={{ style: "error" }}
            >
              Aktywacja nie powiodła się: {errorMessage}
            </Notification>
          </NotificationGroup>
        </p>
      )}

      <div className={moduleStyle.formColumn}>
        <p style={{ width: "400px", textAlign: "justify" }}>
          {errorMessage !== null && (
            <ErrorView message={errorMessage} />
          )}

          {!isSuccessNotificationVisible && !errorMessage && (
            <PleaseWaitView />
          )}

          {isSuccessNotificationVisible && !errorMessage && (
            <ActivationForm />
          )}

        </p>
      </div>
      <PromoBanner />
    </div>
  );
};

export default ActivateAccount;