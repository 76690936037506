import React from 'react';

import UserRoleEnum from '../models/enums/UserRoleEnum';
import BreadcrumbItem from '../models/local/Breadcrumb/BreadcrumbItem';

// --- types

export type AppContextModel = {
  loggedIn: boolean;
  userName: string;
  role: UserRoleEnum | null;
  dicts: any[];
  userId: string;
  specialId: string;
  avatarId?: string | null;

  isLoading: boolean;
  notification: string | undefined;
  notificationStyle?: "none" | "success" | "error" | "warning" | "info";
  breadcrumbs: BreadcrumbItem[];

  currentDateTime: Date | undefined;

  isInMobileView: boolean | undefined;
  mobileBreakpointPx: number | undefined;
};

export interface IAppContext {
  values: AppContextModel;
  setValues: React.Dispatch<React.SetStateAction<AppContextModel>>;
}

// --- default values

export const defaultValues: IAppContext = {
  values: {
    loggedIn: false,
    userName: "",
    role: null,
    dicts: [],
    userId: "",
    specialId: "",
    avatarId: null,

    isLoading: false,
    notification: undefined,
    notificationStyle: "success",
    breadcrumbs: [],

    currentDateTime: undefined,

    isInMobileView: undefined,
    mobileBreakpointPx: undefined
  },
  setValues: {} as React.Dispatch<React.SetStateAction<AppContextModel>>,
};

export const AppContext = React.createContext<IAppContext>(defaultValues);
