import { DataResult } from '@progress/kendo-data-query';

import WorkTimePersonalGridItem from './WorkTimePersonalGridItem';

export default class WorkTimePersonalTimeLogGridViewState {
    GridItems: WorkTimePersonalGridItem[] = [];
    GroupedGridItems: DataResult;
    Date: Date = new Date();

    totalHours: number = 0;
    totalMinutes: number = 0;

    deleteDialogVisible: boolean;
    idItemToDelete: string | undefined;

    editDescriptionDialogVisible: boolean;
    editDescriptionId: string | undefined;
}