import React, { CSSProperties, useEffect } from 'react';

import { TimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { Field } from '@progress/kendo-react-form';

import { DaysEnumTranslation } from '../../../../models/enums/DaysEnum';
import WorkPlanItem from '../../../../models/local/WorkPlan/WorkPlanItem';
import { requiredNoLabelValidator } from '../../../../validators/CommonValidators';
import { FormInput, FormTimePicker } from '../../../Kendo/form-components';

const localStyle: { [key: string]: CSSProperties } = {
  itemRow: {
    width: "750px",
    height: "2.5em",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"
  },
}

/**
 * Formats time duration to a valid TimeSpan format (hh:mm)
 */
const formatTimeSpan = (hours: number, minutes: number): string => {
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

/**
 * Calculates hours difference between two times, handling overnight shifts
 * and 24-hour shifts (00:00 to 00:00)
 */
const calculateHoursDiff = (hourFrom: Date | null, hourTo: Date | null) => {
  if (!hourFrom || !hourTo) {
    return { hours: 0, asString: "" };
  }

  // Extract only hours and minutes from dates
  const startHours = hourFrom.getHours();
  const startMinutes = hourFrom.getMinutes();
  const endHours = hourTo.getHours();
  const endMinutes = hourTo.getMinutes();

  // Special case: if both times are 00:00, interpret as 24-hour shift
  if (startHours === 0 && startMinutes === 0 && endHours === 0 && endMinutes === 0) {
    return { hours: 24, asString: "24:00" };
  }

  // Calculate total minutes for each time
  let startTotalMinutes = startHours * 60 + startMinutes;
  let endTotalMinutes = endHours * 60 + endMinutes;

  // Handle overnight case
  if (endTotalMinutes < startTotalMinutes) {
    endTotalMinutes += 24 * 60; // Add 24 hours in minutes
  }

  // Calculate difference in minutes
  const diffMinutes = endTotalMinutes - startTotalMinutes;

  // Convert to hours and minutes
  const hours = Math.floor(diffMinutes / 60);
  const minutes = diffMinutes % 60;

  const asString = formatTimeSpan(hours, minutes);

  return { hours: hours + minutes / 60, asString };
};

const DayHoursComponentItem = (props: {
  workPlanItem: WorkPlanItem,
  onUpdateWorkPlanItem: (workPlanItem: WorkPlanItem) => void,
  readonly: boolean
}) => {

  const handlehourFromChange = (event: TimePickerChangeEvent) => {
    if (!event.value) return;

    const hourFrom = event.value as Date;
    const diff = calculateHoursDiff(hourFrom, props.workPlanItem.hourTo);

    props.onUpdateWorkPlanItem({
      ...props.workPlanItem,
      hourFrom: hourFrom,
      hoursSum: diff.hours > 0 ? diff.asString : ""
    });
  };

  const handlehourToChange = (event: TimePickerChangeEvent) => {
    if (!event.value) return;

    const hourTo = event.value as Date;
    const diff = calculateHoursDiff(props.workPlanItem.hourFrom, hourTo);

    props.onUpdateWorkPlanItem({
      ...props.workPlanItem,
      hourTo: hourTo,
      hoursSum: diff.hours > 0 ? diff.asString : ""
    });
  };

  useEffect(() => {
    console.log("DayHoursComponentItem", props);
  }, [props]);

  return (
    <div style={localStyle.itemRow}>
      <div style={{ width: "200px" }}>{DaysEnumTranslation(props.workPlanItem.day)}</div>
      <div style={{ width: "400px" }}>
        <Field
          id={`hourFrom${props.workPlanItem.day.toUpperCase()}`}
          name={`hourFrom${props.workPlanItem.day.toUpperCase()}`}
          component={FormTimePicker}
          width={130}
          onChange={handlehourFromChange}
          disabled={props.readonly || !props.workPlanItem.enabled}
          label="od&nbsp;"
          wrapperStyle={{ display: "inline-flex", alignItems: "center" }}
          validator={requiredNoLabelValidator}
        />

        <Field
          id={`hourTo${props.workPlanItem.day.toUpperCase()}`}
          name={`hourTo${props.workPlanItem.day.toUpperCase()}`}
          component={FormTimePicker}
          width={130}
          onChange={handlehourToChange}
          disabled={props.readonly || !props.workPlanItem.enabled}
          label="&nbsp;do&nbsp;"
          wrapperStyle={{ display: "inline-flex", alignItems: "center" }}
          validator={requiredNoLabelValidator}
        />
      </div>

      <div style={{ width: "120px" }}>
        {props.workPlanItem.enabled ?
          <span>
            <Field
              id={`hoursSum${props.workPlanItem.day.toUpperCase()}`}
              name={`hoursSum${props.workPlanItem.day.toUpperCase()}`}
              component={FormInput}
              disabled={true}
              wrapperStyle={{ display: "inline-flex", alignItems: "center" }}
              label="&nbsp;"
              validator={requiredNoLabelValidator}
            />
          </span> : <span>---</span>
        }
      </div>
    </div>
  );
};

export default DayHoursComponentItem;