import AppModulesEnum from '../../dto/Common/AppModulesEnum';

export default class LicenceList {
    officeId: string;
    officeName: string;
    validFrom: Date;
    validTo: Date;
    workersLimit: number;
    id: string;
    promoCode: string;
    lastOfficeUserLoginDate: Date;
    isValid: boolean;
    availableModules: AppModulesEnum[];
}