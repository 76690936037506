import React from 'react';

interface PrintFooterProps {
    pageNum?: number;
    pagesTotal?: number;
}

const PrintFooter = ({ pageNum, pagesTotal }: PrintFooterProps) => {
    const PRINT_footer = "Wydruk przygotowano w programie Synerdi.pl";
    const showPageInfo = pageNum !== undefined && pagesTotal !== undefined;

    return (
        <div
            style={{
                position: "absolute",
                bottom: "40px",
                left: "40px",
                right: "40px",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <p style={{ color: "gray" }}>
                {PRINT_footer}
            </p>
            {showPageInfo && (
                <p style={{ color: "gray" }}>
                    Strona {pageNum} z {pagesTotal}
                </p>
            )}
        </div>
    );
};

export default PrintFooter;