import LeaveStatusEnum from '../../enums/LeaveStatusEnum';
import CalculateApp from '../AddLeave/CalculateApp';
import DropdownItem from '../Dropdown/DropdownItem';

export default class LeaveListItem {
  id: string;
  limitType: string;
  ownerName: string;
  startDate: Date;
  endDate: Date;
  replacementUsers: DropdownItem[];
  comment: string;
  hrComment: string;
  status: LeaveStatusEnum;
  applicantUserName: string;
  applicantUserId: string;
  applicantDepartmentsNames: string;

  calculations: CalculateApp;
}
