import React from 'react';

import { GridPDFExport } from '@progress/kendo-react-pdf';

import PrintFooter from '../../_common/CustomProgressBar/PrintFooter';

interface LeavesPdfExporterProps {
    children: React.ReactNode;
    exporterRef: (exporter: GridPDFExport) => void;
}

/**
 * Component responsible for exporting leave applications to PDF
 */
const LeavesPdfExporter = ({ children, exporterRef }: LeavesPdfExporterProps) => {
    return (
        <GridPDFExport
            ref={exporterRef}
            paperSize="A4"
            scale={0.5}
            margin={{ top: "25mm", right: "15mm", left: "10mm", bottom: "15mm" }}
            pageTemplate={(props: any) => {
                return (
                    <>
                        <div
                            style={{
                                position: "absolute",
                                top: "60px",
                                left: "40px",
                            }}
                        >
                            <p>Raport HR - złożone wnioski urlopowe<br />
                            </p>
                        </div>
                        <PrintFooter />
                    </>
                )
            }}
        >
            {children}
        </GridPDFExport>
    );
};

export default LeavesPdfExporter;