import '../../../../../../styles/_common.scss';

import React, { useEffect, useState } from 'react';

import { process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import {
  Grid, GridColumn as Column, GridExpandChangeEvent, GridRowClickEvent
} from '@progress/kendo-react-grid';

import LimitType from '../../../../../../models/local/Employees/LimitType';
import LimitTypeOverride from '../../../../../../models/local/Employees/LimitTypeOverride';
import LimitTypeOverrideItem from '../../../../../../models/local/Employees/LimitTypeOverrideItem';
import UserLeaveStats from '../../../../../../models/local/Leaves/UserLeaveStats';
import { CellDictToName, DictTypes } from '../../../../../../scripts/dictProvider';
import YearSelector from '../../../../../Common/YearSelector';
import { LimitDetailGridComponent } from './LimitDetailGridComponent';

const LimitsList = (props:
  {
    year: number;
    setYear: (year: number) => void;
    globalLimits: LimitType[];
    individualLimits: LimitTypeOverride[];
    leaveStats: UserLeaveStats;
    onAddItem: (entity: LimitTypeOverride) => void;
    onEditItem: (item: LimitTypeOverrideItem) => void;
    onDeleteItem: (item: LimitTypeOverrideItem) => void;
    onVisibilityChange: (entityId: string, visibility: boolean) => void;
    readonly: boolean,
    yearReadonly: boolean,
  }) => {
  const [limitTypesForYear, setLimitTypesForYear] = useState<LimitType[]>([]);
  const [limitTypesOverridesForYear, setLimitTypesOverridesForYear] = useState<LimitTypeOverride[]>(props.individualLimits);
  const [leaveStats, setLeaveStats] = useState<UserLeaveStats>(props.leaveStats);

  const onExpandChange = (event: GridExpandChangeEvent) => {
    event.dataItem.expanded = event.value;
    setLimitTypesOverridesForYear(props.individualLimits.filter((lt) => lt.year === props.year)); //force grid refresh
  };

  const onRowClick = (event: GridRowClickEvent) => {
    event.dataItem.expanded = !event.dataItem.expanded;
    setLimitTypesOverridesForYear(props.individualLimits.filter((lt) => lt.year === props.year)); //force grid refresh
  };

  let countUsedDaysByTypeId = (leaveTypeId: string) => {
    var stats = leaveStats?.leavesGroupedByType?.find((g) => g.leaveTypeId === leaveTypeId);
    return <span>{stats?.count ?? "0"}</span>;
  };

  useEffect(() => {
    if (props.individualLimits?.length > 0) setLimitTypesOverridesForYear(props.individualLimits.filter((lt) => lt.year === props.year));
    if (props.globalLimits?.length > 0) setLimitTypesForYear(props.globalLimits.filter((lt) => lt.year === props.year));
  }, [props.year, props.individualLimits]);

  useEffect(() => {
    if (props.leaveStats) setLeaveStats(props.leaveStats);
  }, [props.leaveStats]);

  // Check if both limit arrays are empty or null
  const yearNotInitialized = (props.individualLimits?.length === 0 && props.globalLimits?.length === 0);

  console.log("yearNotInitialized", { yearNotInitialized, limitTypesOverridesForYear, limitTypesForYear })

  return (
    <div id="itemsGridWrapper" className={props.readonly ? "contentWrapperFullHeight--withExternalScroll" : ""}>
      <p style={{ fontWeight: 400 }}>
        <YearSelector
          initialYear={props.year}
          onValueChanged={(year) => {
            props.setYear(year);
          }}
          readonly={props.yearReadonly}
          showLabel={true}
        />
      </p>

      {yearNotInitialized ? (
        <div>
          <span>
            Limity nieobecności na rok {props.year} nie zostały jeszcze skonfigurowane w systemie.<br />
            Skontaktuj się z działem kadr Twojej firmy.
          </span>
        </div>
      ) : (
        <div id="limitsDetails">
          <p style={{ fontWeight: 400 }}>
            <b>Indywidualne</b> limity nieobecności pracownika
          </p>

          <Grid
            id="gridUserLimitsPreview"
            style={{ height: "100%" }}
            data={limitTypesOverridesForYear}
            detail={(items) => {
              return LimitDetailGridComponent({
                items: items,
                year: props.year,
                onDeleteItem: props.onDeleteItem,
                onEditItem: props.onEditItem,
                readonly: props.readonly,
              });
            }}
            expandField="expanded"
            onExpandChange={onExpandChange}
            onRowClick={onRowClick}
          >
            <Column
              field="name"
              title="Rodzaj nieobecności"
              cell={(v: any) => {
                return (
                  <>
                    <td>{(v.dataItem as LimitType).name}</td>
                  </>
                );
              }}
            />
            <Column
              field="limit"
              title="Wykorzystanie"
              width="200px"
              cell={(v: any) => {
                let sum: number = 0;
                (v.dataItem as LimitTypeOverride).items.forEach((v) => {
                  sum += Number(v.limit);
                });
                return (
                  <td>
                    {countUsedDaysByTypeId((v.dataItem as LimitTypeOverride).parentId)} z {sum} dni
                  </td>
                );
              }}
            />
            {!props.readonly && <Column field="visible" title="&nbsp;" cell={(v: any) => <td>{v.dataItem.visible === true ? <span style={{ color: "green", fontWeight: 500 }}>Dostępny dla pracownika</span> : <span style={{ color: "red" }}>Niedostępny dla pracownika</span>}</td>} />}
            {!props.readonly && (
              <Column
                title="Operacje"
                width="380px"
                cell={(v: any) => {
                  return (
                    <td style={{ textAlign: "right" }}>
                      {v.dataItem.visible === true ? (
                        <Button
                          fillMode="outline"
                          icon="close"
                          onClick={() => {
                            props.onVisibilityChange(v.dataItem.id, false);
                          }}
                        >
                          Zmień na niedostępny
                        </Button>
                      ) : (
                        <Button
                          fillMode="outline"
                          icon="check"
                          onClick={() => {
                            props.onVisibilityChange(v.dataItem.id, true);
                          }}
                        >
                          Zmień na dostępny
                        </Button>
                      )}{" "}
                      {v.dataItem.limitScope === "Individual" && (
                        <Button
                          fillMode="outline"
                          icon="add"
                          onClick={() => {
                            props.onAddItem(v.dataItem as LimitTypeOverride);
                          }}
                        >
                          Zwiększ limit
                        </Button>
                      )}
                    </td>
                  );
                }}
              />
            )}
          </Grid>

          <p style={{ fontWeight: 400, marginTop: "30px" }}>
            Pozostałe limity nieobecności pracownika ustalone <b>globalnie</b>
            {!props.readonly && <span className="k-form-hint k-text-start">(zmiana limitów globalnych możliwa jest w widoku Ustawienia ➡ Limity urlopowe)</span>}
          </p>

          <Grid
            style={{ height: "100%" }}
            data={process(limitTypesForYear, {
              sort: [{ field: "name", dir: "asc" }],
            })}
            sort={[{ field: "name", dir: "asc" }]}
            sortable={true}
          >
            <Column
              field="name"
              title="Rodzaj nieobecności"
              cell={(v: any) => {
                return (
                  <>
                    <td style={{}}>{(v.dataItem as LimitType).name}</td>
                  </>
                );
              }}
            />
            <Column
              field="limitScope"
              title="Rodzaj limitu"
              cell={(v: any) => {
                return <>{CellDictToName(v, DictTypes.LimitTypeScopeEnum, v.dataItem.limitScope === "Individual" ? "green" : "black")}</>;
              }}
            />
            <Column
              field="limit"
              title="Wykorzystanie"
              width="200px"
              cell={(v: any) => {
                let item = v.dataItem as LimitType;
                return (
                  <td>
                    {countUsedDaysByTypeId((v.dataItem as LimitType).id)} z {item.limit === 0 ? "bez limitu" : item.limit + " dni"}
                  </td>
                );
              }}
            />
            {!props.readonly && <Column field="visible" title="Dostępny" cell={(v: any) => <td>{v.dataItem.visible === true ? <span style={{ color: "green", fontWeight: 500 }}>Tak</span> : <span style={{ color: "red" }}>Nie</span>}</td>} />}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default LimitsList;