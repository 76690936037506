import React, { useRef, useState } from 'react';

import { QRCode } from '@progress/kendo-react-barcodes';
import { Button, Toolbar, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Checkbox } from '@progress/kendo-react-inputs';
import { PDFExport } from '@progress/kendo-react-pdf';

interface EmployeeData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    specialId: string;
}

interface BulkQRGeneratorProps {
    employees: EmployeeData[];
    visible: boolean;
    onClose: () => void;
}

const BulkQRGenerator: React.FC<BulkQRGeneratorProps> = ({ employees, visible, onClose }) => {
    const [showFullData, setShowFullData] = useState(true);
    const pdfExportComponent = useRef<PDFExport>(null);
    const container = useRef<HTMLDivElement>(null);

    if (!visible) return null;

    const exportPDF = () => {
        if (pdfExportComponent.current) {
            const dialogElement = document.querySelector('.k-dialog');
            const originalDialogStyle = dialogElement ? dialogElement.getAttribute('style') : '';

            if (dialogElement) {
                dialogElement.setAttribute('style', 'width: auto; height: auto; position: absolute; overflow: visible;');
            }

            pdfExportComponent.current.save();

            setTimeout(() => {
                if (dialogElement && originalDialogStyle) {
                    dialogElement.setAttribute('style', originalDialogStyle);
                }
            }, 100);
        }
    };

    return (
        <Dialog
            title="Kody QR pracowników"
            onClose={onClose}
            width="80%"
            height="80%"
        >
            <Toolbar>
                <ToolbarItem>
                    <Checkbox
                        label="Pokaż pełne dane"
                        checked={showFullData}
                        onChange={(e) => setShowFullData(e.value)}
                    />
                </ToolbarItem>
                <ToolbarSpacer />
                <ToolbarItem>
                    <Button
                        icon="printer"
                        fillMode="outline"
                        onClick={exportPDF}
                    >
                        Drukuj
                    </Button>
                    &nbsp;
                    <Button
                        fillMode="outline"
                        onClick={onClose}
                    >
                        Zamknij
                    </Button>
                </ToolbarItem>
            </Toolbar>

            <PDFExport
                ref={pdfExportComponent}
                paperSize="A4"
                margin={20}
                fileName={`Kody_QR_pracownikow_${new Date().toISOString().split('T')[0]}`}
                author="System"
                scale={0.5}
                landscape={false}
                forcePageBreak=".page-break"
            >
                <div ref={container} className="qr-code-grid" style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '20px',
                    padding: '20px'
                }}>
                    {employees.map(employee => (
                        <div key={employee.id} style={{
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            padding: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                <p style={{ fontWeight: 'bold', fontSize: '16px', margin: '0 0 5px 0' }}>
                                    {employee.firstName} {employee.lastName}
                                </p>
                                {showFullData && (
                                    <p style={{ margin: '0 0 10px 0', color: '#666' }}>
                                        {employee.email}
                                    </p>
                                )}
                                <hr style={{ width: '100%', borderStyle: 'dashed', margin: '10px 0' }} />
                            </div>
                            <QRCode
                                value={employee.specialId || employee.id}
                                errorCorrection="H"
                                size={300}
                                renderAs='canvas'
                                encoding='UTF_8'
                            />
                            {showFullData && (
                                <p style={{ fontSize: '11px', marginTop: '10px', color: '#999' }}>
                                    ID: {employee.specialId || employee.id.substring(0, 8)}...
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </PDFExport>
        </Dialog>
    );
};

export default BulkQRGenerator;