import LeaveStatusEnum from '../../enums/LeaveStatusEnum';
import DropdownItemResponse from '../Dropdown/DropdownItemResponse';
import CalculateAppResponseDto from './Responses/CalculateAppResponseDto';

export default class LeaveListItemDto {
  id: string;
  limitType: string;
  ownerName: string;
  startDate: Date;
  endDate: Date;
  replacementUsers: DropdownItemResponse[];
  comment: string;
  hrComment: string;
  status: LeaveStatusEnum;
  applicantUserName: string;
  applicantUserId: string;
  applicantDepartmentsNames: string;

  calculations: CalculateAppResponseDto;
}