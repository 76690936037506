import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { Field, Form, FormRenderProps } from '@progress/kendo-react-form';

import { apiClient } from '../../../api/apiClient';
import {
    LEAVE_GET_replacementUsers, LEAVE_GET_subordinateUsers, LEAVE_POST, LEAVE_POST_calculateApp,
    LIMITS_GET_allForCurrentUserDDI, LIMITS_GET_allForUserDDI, SETTINGS_GET
} from '../../../api/apiEndpoints';
import DropdownItemResponse from '../../../models/dto/Dropdown/DropdownItemResponse';
import AddLeaveRequestDto from '../../../models/dto/Leaves/Requests/AddLeaveRequestDto';
import CalculateAppRequestDto from '../../../models/dto/Leaves/Requests/CalculateAppRequestDto';
import CalculateAppResponseDto from '../../../models/dto/Leaves/Responses/CalculateAppResponseDto';
import SettingsParametersResponse from '../../../models/dto/Settings/Responses/SettingsParametersResponse';
import LeaveDaySourceEnum from '../../../models/enums/LeaveDaySourceEnum';
import NotificationEnum from '../../../models/enums/NotificationEnum';
import AddLeaveForm from '../../../models/local/AddLeave/AddLeaveForm';
import AddLeaveViewState from '../../../models/local/AddLeave/AddLeaveViewState';
import CalculateApp from '../../../models/local/AddLeave/CalculateApp';
import CalculateAppItem from '../../../models/local/AddLeave/CalculateAppItem';
import DropdownItem from '../../../models/local/Dropdown/DropdownItem';
import UserLeaveStats from '../../../models/local/Leaves/UserLeaveStats';
import AllUserLimits from '../../../models/local/Limits/AllUserLimits';
import SettingsParameters from '../../../models/local/Settings/Responses/SettingsParameters';
import { DateToDateAsString, mapper } from '../../../models/mapper';
import { AppContext, IAppContext } from '../../../services/AppContext';
import useAppLoader from '../../../services/AppLoader';
import useAppNotifications from '../../../services/AppNotifications';
import moduleStyle from '../../../styles/AddAppForm.module.scss';
import { requiredValidator } from '../../../validators/CommonValidators';
import EditToolbar from '../../Common/EditToolbar';
import {
    FormCheckbox, FormComboBox, FormDatePicker, FormInput, FormMultiSelect, FormTextArea
} from '../../Kendo/form-components';
import UserLimits from '../UserLimits';
import AppAppFormExplanations from './AppAppFormExplanations';

export const AddAppForm = (props: {
    year: number,
    userLimits: AllUserLimits,
    userLeaveStats: UserLeaveStats,

    onSetUserId: (id?: string) => void;
    onAppDaysDetailsChanged: (items: CalculateAppItem[]) => void;
}) => {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const notifications = useAppNotifications();
    const context = React.useContext<IAppContext>(AppContext);
    const loader = useAppLoader(true);

    const [viewState, setViewState] = React.useState<AddLeaveViewState>(
        {
            calculatedApp: new CalculateApp(),

            startDate: new Date(),
            endDate: new Date(),
            onBehalfOfDDItem: null,
            onOwnBehalfChecked: true,

            limitType: undefined,

            isOverlappingWithExistingRequest: false
        });

    const [userLimitTypes, setUserLimitTypes] = React.useState<DropdownItem[]>();
    const [replacementUsers, setReplacementUsers] = React.useState<DropdownItem[]>();
    const [subordinateUsers, setSubordinateUsers] = React.useState<DropdownItem[]>([]);
    const [settings, setSettings] = useState<SettingsParameters>();

    const handleDateChange = (e: any) => {
        setViewState({ ...viewState, [e.target.name as string]: e.value as Date });
    };

    const onOwnBehalfChanged = (e: any) => {
        loader.showLoading(true);

        setViewState({ ...viewState, limitType: undefined, onOwnBehalfChecked: e.value, onBehalfOfDDItem: null, calculatedApp: new CalculateApp() });
    };

    const onBehalfOfChanged = (e: any) => {
        setViewState({ ...viewState, onBehalfOfDDItem: e?.value });
    };

    const onLimitTypeChanged = (e: any) => {
        setViewState({ ...viewState, limitType: e?.value });
    };

    const onSubmitButtonClick = (formProps: FormRenderProps, e: any) => {
        if (!formRef.current?.isValid()) {
            formProps.onSubmit(e); //force execute validation
            notifications.showNotification("Zapisanie danych nie udało się ponieważ formularz zawiera błędy.", NotificationEnum.Error);
        }

        formProps.onSubmit(e);
    };

    const onFormSubmit = (form: any) => {
        const dtoToSend: AddLeaveRequestDto = mapper.map(form as AddLeaveForm, AddLeaveRequestDto, AddLeaveForm);

        apiClient({
            method: "POST",
            url: LEAVE_POST,
            data: dtoToSend,
        }).then(async (resp) => {
            if (resp.status !== 200) {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error);
                return;
            } else {

                notifications.showNotification("Wniosek został złożony poprawnie", NotificationEnum.Success);
                navigate("/appslist");
            }
        });
    };

    const loadSettingsData = async () => {
        const getSettings = async () => {
            await apiClient({
                method: "GET",
                url: SETTINGS_GET,
                data: {},
            }).then((resp) => {
                const mappedResult: SettingsParameters = mapper.map(resp?.data?.result, SettingsParameters, SettingsParametersResponse);

                setSettings(mappedResult);
            });
        };

        await getSettings();
    };

    const getUserLimitsData = async (year: number) => {
        const userId = getUserIdForRequests();

        const resp = await apiClient({
            method: "GET",
            url: userId ? LIMITS_GET_allForUserDDI(userId, year) : LIMITS_GET_allForCurrentUserDDI(year),
            data: {},
        });

        if (resp.status !== 200) {
            notifications.showNotification(resp.data.errors, NotificationEnum.Error);
            return;
        }

        const result: DropdownItemResponse[] = resp.data.result as DropdownItemResponse[];
        const mappedResult: DropdownItem[] = mapper.mapArray(result, DropdownItem, DropdownItemResponse);

        setUserLimitTypes(mappedResult);
    };

    const getUserIdForRequests = () => viewState?.onBehalfOfDDItem?.id ?? context.values.userId;

    const getReplacementUsers = async (id: string) => {
        const resp = await apiClient({
            method: "GET",
            url: LEAVE_GET_replacementUsers(id),
            data: {},
        });

        if (resp.status !== 200) {
            notifications.showNotification(resp.data.errors, NotificationEnum.Error);
            return;
        }

        const result: DropdownItemResponse[] = resp.data.result as DropdownItemResponse[];
        const mappedResult: DropdownItem[] = mapper.mapArray(result, DropdownItem, DropdownItemResponse);

        setReplacementUsers(mappedResult);
    };

    const getSubordinateUsers = async () => {
        const resp = await apiClient({
            method: "GET",
            url: LEAVE_GET_subordinateUsers,
            data: {},
        });

        if (resp.status !== 200) {
            notifications.showNotification(resp.data.errors, NotificationEnum.Error);
            return;
        }

        const result: DropdownItemResponse[] = resp.data.result as DropdownItemResponse[];
        const mappedResult: DropdownItem[] = mapper.mapArray(result, DropdownItem, DropdownItemResponse);

        setSubordinateUsers(mappedResult);
    };

    const calculateApp = async () => {
        apiClient({
            method: "POST",
            url: LEAVE_POST_calculateApp,
            data: new CalculateAppRequestDto(viewState?.onBehalfOfDDItem?.id ?? context.values.userId, DateToDateAsString(viewState.startDate), DateToDateAsString(viewState.endDate)),
        }).then((resp) => {
            loader.showLoading(false);

            if (resp.status !== 200) {
                notifications.showNotification(resp.data.errors, NotificationEnum.Error);
                return;
            }

            const mappedResult: CalculateApp = mapper.map(resp.data.result, CalculateApp, CalculateAppResponseDto);

            mappedResult.isCalculated = true;
            setViewState({ ...viewState, calculatedApp: mappedResult });
        });
    };

    // -----
    React.useEffect(() => {
        props.onSetUserId(getUserIdForRequests());

        loader.showLoading(true);
        Promise.all([loadSettingsData()])
            .then(() => {

                loader.showLoading(false);
            });
    }, []);

    React.useEffect(() => {
        props.onSetUserId(getUserIdForRequests());

        loader.showLoading(true);
        Promise
            .all([getReplacementUsers(getUserIdForRequests()), getSubordinateUsers()])
            .then(() => { loader.showLoading(false); });
    }, [viewState.onBehalfOfDDItem, viewState.onOwnBehalfChecked]);

    React.useEffect(() => {
        loader.showLoading(true);

        if (viewState.startDate > viewState.endDate) {
            setViewState({ ...viewState, endDate: viewState.startDate });
            return;
        }

        loader.showLoading(true);
        Promise
            .all([getUserLimitsData(viewState.endDate.getFullYear())])
            .then(() => { loader.showLoading(false); });

        if (viewState.onOwnBehalfChecked === true || viewState?.onBehalfOfDDItem?.id !== undefined)
            calculateApp();
        else
            loader.showLoading(false);

    }, [viewState.startDate, viewState.endDate, viewState.onBehalfOfDDItem, viewState.onOwnBehalfChecked, viewState.limitType]);

    React.useEffect(() => {
        props.onAppDaysDetailsChanged(viewState.calculatedApp.dates);

        viewState.isOverlappingWithExistingRequest = false;
        viewState.calculatedApp.dates.forEach(i => {
            if (i.source === LeaveDaySourceEnum.ALREADYUSEDINANOTHERLEAVE)
                viewState.isOverlappingWithExistingRequest = true;
        });

    }, [viewState.calculatedApp]);

    React.useEffect(() => {
    }, [props.userLeaveStats, props.userLimits]);

    return (
        <>
            {!loader.isLoading() && (
                <div>
                    <Form
                        onSubmit={onFormSubmit}
                        ignoreModified={true}
                        initialValues={{
                            ...viewState,
                            ownerName: context.values.userName,
                            onBehalfOf: viewState.onBehalfOfDDItem,
                            onOwnBehalf: viewState.onOwnBehalfChecked,
                            limitType: viewState.limitType
                        }}
                        ref={formRef}
                        render={(formRenderProps: FormRenderProps) => (
                            <>
                                {viewState.calculatedApp.isCalculated &&
                                    <>
                                        <AppAppFormExplanations currentAppAllDaysSum={viewState?.calculatedApp.allDaysSum} currentAppWorkingDaysSum={viewState?.calculatedApp.workingDaysSum} userLeaveStats={props.userLeaveStats} userLimits={props.userLimits} selectedLimitType={viewState.limitType} isOverlappingWithExistingRequest={viewState.isOverlappingWithExistingRequest} />
                                    </>
                                }

                                <Field
                                    id={"ownerName"}
                                    name={"ownerName"}
                                    autoComplete="off"
                                    label={"Składający wniosek"}
                                    component={FormInput}
                                    readOnly={true}
                                    disabled={true}
                                    markRequired={true}
                                    wrapperClass={moduleStyle.formField} />

                                <Field
                                    id={"onOwnBehalf"}
                                    name={"onOwnBehalf"}
                                    label={"Składam wniosek we własnym imieniu"}
                                    component={FormCheckbox}
                                    onChange={onOwnBehalfChanged}
                                    hint={subordinateUsers.length === 0 ?
                                        "Nie masz możliwości wystawiania wniosków w imieniu innych osób. Opcja ta jest aktywna tylko dla kont z uprawnieniami do rozpatrywania wniosków w określonych działach firmy." : "(odznacz pole i wskaż poniżej osobę jeżeli chcesz złożyć wniosek za podwładnego)"}
                                    wrapperClass={moduleStyle.formField}
                                    disabled={subordinateUsers.length === 0} />

                                {subordinateUsers.length > 0 && !viewState.onOwnBehalfChecked && (
                                    <Field
                                        id={"onBehalfOf"}
                                        name={"onBehalfOf"}
                                        label={"Osoba, której dotyczy wniosek"}
                                        textField="text"
                                        component={FormComboBox}
                                        data={subordinateUsers}
                                        hint={"(pozostaw pole puste jeśli wystawiasz wniosek w swoim imieniu)"}
                                        readonly={true}
                                        dataItemKey="id"
                                        onChange={onBehalfOfChanged}
                                        markRequired={true}
                                        validator={requiredValidator}
                                        wrapperClass={moduleStyle.formField} />)}



                                <Field
                                    id={"limitType"}
                                    name={"limitType"}
                                    label={"Rodzaj nieobecności"}
                                    textField="text"
                                    dataItemKey="id"
                                    component={FormComboBox}
                                    data={userLimitTypes}
                                    validator={requiredValidator}
                                    hint={"(np. urlop wypoczynkowy, urlop na żądanie)"}
                                    markRequired={true}
                                    onChange={onLimitTypeChanged}
                                    wrapperClass={moduleStyle.formField} />



                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="k-form-field" style={{ width: "45%" }}>
                                        <Field
                                            id={"startDate"}
                                            name={"startDate"}
                                            label={"Data rozpoczęcia"}
                                            component={FormDatePicker}
                                            validator={requiredValidator}
                                            onChange={handleDateChange}
                                            markRequired={true}
                                            wrapperClass={moduleStyle.formField} />
                                    </div>

                                    <div className="k-form-field" style={{ width: "45%" }}>
                                        <Field
                                            id={"endDate"}
                                            name={"endDate"}
                                            label={"Zakończenia"}
                                            component={FormDatePicker}
                                            validator={requiredValidator}
                                            hint={"(włącznie)"}
                                            onChange={handleDateChange}
                                            markRequired={true}
                                            wrapperClass={moduleStyle.formField} />
                                    </div>
                                </div>


                                <Field
                                    id={"replacementUsers"}
                                    name={"replacementUsers"}
                                    label={"Osoby zastępujące podczas nieobecności"}
                                    textField="text"
                                    component={FormMultiSelect}
                                    data={replacementUsers}
                                    validator={(settings?.replacementRequired ?? false) ? requiredValidator : undefined}
                                    hint={"(osoby, które będą zastępować składającego wniosek podczas jego nieobecności)"}
                                    markRequired={(settings?.replacementRequired ?? false) ? true : false}
                                    dataItemKey="id"
                                    wrapperClass={moduleStyle.formField} />

                                <Field
                                    label={"Komentarz składającego wniosek"}
                                    id={"comment"}
                                    name={"comment"}
                                    autoComplete="off"
                                    component={FormTextArea}
                                    hint={"(opcjonalne uzasadnienie złożonego wniosku)"}
                                    wrapperClass={moduleStyle.formField} />

                                <p></p>

                                <EditToolbar onSaveLabel="Złóż wniosek" onSubmitClick={(e: any) => { onSubmitButtonClick(formRenderProps, e); }} onCancelClick={() => { navigate("/appslist"); }} useSpacer={true} useColors={true} />
                            </>
                        )} />

                </div>)}
        </>
    );
};
