import React, { ReactNode, useState } from 'react';

import { CompositeFilterDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';
import {
    Grid, GridDataStateChangeEvent, GridSelectionChangeEvent
} from '@progress/kendo-react-grid';

import { apiClient } from '../../api/apiClient';
import NotificationEnum from '../../models/enums/NotificationEnum';
import useAppLoader from '../../services/AppLoader';
import useAppNotifications from '../../services/AppNotifications';

export interface PaginatedGridRef {
    reload: () => void;
    getState: () => PaginatedGridState;
}

export interface PaginatedGridState extends State {
    skip: number;
    take: number;
    filter: CompositeFilterDescriptor;
    sort: SortDescriptor[];
    total: number;
    data?: any[];
}

interface PaginatedGridProps {
    style?: React.CSSProperties;
    className?: string;
    children?: ReactNode;
    url: string;
    filterable: boolean;
    sortable: boolean;
    filter: CompositeFilterDescriptor;
    sort: SortDescriptor[];
    callbackMapping?: (data: any[]) => any[];
    onSelectionChange?: (event: GridSelectionChangeEvent) => void;
}

export const PaginatedDataGrid = React.forwardRef(({
    url,
    filter,
    sort,
    callbackMapping,
    ...props
}: PaginatedGridProps, ref: React.ForwardedRef<PaginatedGridRef>) => {
    const loader = useAppLoader(true);
    const notifications = useAppNotifications();

    const [gridState, setGridState] = useState<PaginatedGridState>({
        skip: 0,
        take: 20,
        filter,
        sort,
        total: 0,
        data: undefined
    });

    const fetchData = async (state: PaginatedGridState) => {
        try {
            loader.showLoading(true);

            const { skip, take, filter, sort } = state;
            const response = await apiClient({
                method: "POST",
                url,
                data: { skip, take, filter, sort }
            });

            if (response.status !== 200) {
                const errorMessage = Array.isArray(response.data?.errors)
                    ? response.data.errors
                    : [response.data?.errors || 'Wystąpił błąd podczas pobierania danych'];
                notifications.showNotification(errorMessage, NotificationEnum.Error);
                return;
            }

            const result = response.data.result;
            const mappedData = callbackMapping && result?.data
                ? callbackMapping(result.data)
                : result?.data;

            setGridState(prevState => ({
                ...prevState,
                skip,
                take,
                filter,
                sort,
                data: mappedData,
                total: result?.total ?? 0
            }));

            loader.showLoading(false);
        } catch (error) {
            loader.showLoading(false);
            const errorMessage = error instanceof Error
                ? error.message
                : 'Wystąpił nieoczekiwany błąd';
            notifications.showNotification(errorMessage, NotificationEnum.Error);
        } finally {
            loader.showLoading(false);
        }
    };

    const handleDataStateChange = async (event: GridDataStateChangeEvent) => {
        const newState = { ...gridState, ...event.dataState };
        setGridState(newState);

        try {
            await fetchData(newState);
        } catch (error) {
            console.error('Error handling data state change:', error);
        }
    };

    React.useEffect(() => {
        fetchData(gridState)
            .catch(error => {
                console.error('Error in useEffect fetch:', error);
            });
    }, []);

    React.useImperativeHandle(ref, () => ({
        reload: () => {
            fetchData(gridState);
        },
        getState: () => gridState
    }));

    return (
        <Grid
            {...props}
            pageable={{ pageSizes: true }}
            total={gridState.total}
            data={gridState.data}
            skip={gridState.skip}
            pageSize={gridState.take}
            filter={gridState.filter}
            sort={gridState.sort}
            onDataStateChange={handleDataStateChange}
        />
    );
});