import linq from 'linq';
import React from 'react';

import { GridCellProps } from '@progress/kendo-react-grid';

import { apiClient } from '../api/apiClient';
import { DICT_GET_all as DICT_GET_allWithEnums } from '../api/apiEndpoints';
import DropdownItem from '../models/local/Dropdown/DropdownItem';
import { AppContext, IAppContext } from '../services/AppContext';

export enum DictTypes {
    // dictionaries from db
    ContractPeriods = "e1b162a0-85c7-4ea8-bae5-9b5d6518338f",
    ContractTypes = "a0137821-03c4-4441-8e9e-fc65aa591f2c",
    MedicalTestingTypes = "8c21a195-0a29-4222-a18f-41c73d9c06c0",
    TrainingTypes = "91b1cc86-af7d-4b6b-bd16-f4114c431dfb",
    NotificationTypes = "0a0aaa61-1ddd-4cc6-b516-1eeff4cacb57",
    CompetencyTypes = "9a2c0d6e-09c9-48c2-a796-ffc6b94d321b",
    ResourceTypes = "6c573e02-97b6-412e-860d-8b8748c14d8b",

    // enums
    LimitTypeTreatmentEnum = "LimitTypeTreatmentEnum",
    LimitUnitEnum = "LimitUnitEnum",
    LimitTypeScopeEnum = "LimitTypeScopeEnum",
    LeaveStatusEnum = "LeaveStatusEnum",
    AppModulesEnum = "AppModulesEnum"
}

export class DictItem {
    id: string;
    name: string;

    public constructor(id: string | any, name: string | any) { // | any -> workaround for automapper casting problem
        this.id = id;
        this.name = name;
    }
}

export interface Dict {
    id: string;
    name: string;
    items: DictItem[];
}

export const GetAllDictsAndEnums = async (): Promise<Dict[]> => {
    const getAllDictsResponse = await apiClient({
        method: "GET",
        url: DICT_GET_allWithEnums,
        data: {},
    });

    var result = getAllDictsResponse?.data?.result as Dict[];
    console.log("DictProvider: loaded; items count:", result?.length);

    return result;
}

export const DictByType = (dictType: DictTypes, dicts: Dict[]): DictItem[] => {
    if (dicts?.length === 0) {
        console.warn("DictProvider: DictByType: dicts is empty.");
        return [];
    }

    const dictItems: DictItem[] =
        (dicts.find(v => v.id === dictType) || {}).items || [];

    return dictItems;
}

export const ItemNameById = (dictType: DictTypes, itemId: string, dicts: Dict[]): string => {
    if (!itemId)
        return "";

    const dictItems: DictItem[] = DictByType(dictType, dicts);

    const item = dictItems.find(i => i.id === itemId);
    const name = item?.name || '?not found?';

    return name;
}


export const CellDictToName = (currentRow: GridCellProps, dictType: DictTypes, textColor?: string, noWrapper?: boolean) => {
    const context = React.useContext<IAppContext>(AppContext);

    if (context?.values.dicts === undefined)
        return (<td>?dict empty?</td>);

    const currentCellValue = currentRow.dataItem[currentRow.field as string];
    const returnValue = <div style={{ color: textColor }}>{ItemNameById(dictType, currentCellValue, context.values.dicts)}</div>;

    if (noWrapper === true)
        return (returnValue);
    else
        return (<td>{returnValue}</td>);
}

export const IdToDropDownValue = (dictType: DictTypes, itemId: any, dicts: Dict[]): any => {

    if (itemId?.id) //todo:ech...
        return itemId;

    if (itemId)
        return { id: itemId, name: ItemNameById(dictType, itemId, dicts) };
    else
        return undefined;
}

export const IdArrayToDropDownValues = (dictType: DictTypes, itemsIds: any[], dicts: Dict[]): any[] => {
    if (!itemsIds || !Array.isArray(itemsIds)) {
        return [];
    }

    return itemsIds.map(itemId => {
        if (itemId?.id) {
            return itemId;
        }

        if (itemId) {
            return {
                id: itemId,
                name: ItemNameById(dictType, itemId, dicts)
            };
        }

        return null;
    }).filter(item => item !== null);
};

export const CustomIdToDropDownValue = (dict: DropdownItem[], itemId: string): { id: string, text: string } => {
    return {
        id: itemId,
        text: linq
            .from(dict)
            .first(i => i.id === itemId)
            .text
    };
}

export const DropDownValueMapper = (value: any) => {
    return value?.id
}

export const BooleanToIcon = (value: boolean) => {
    let iconName: string = "";
    switch (value) {
        case true:
            iconName = "k-icon k-i-check";
            break;
        case false:
            iconName = "k-icon k-i-minus-sm";
            break;
    }
    return <td><span className={iconName} style={{ float: "left", marginRight: "5px" }} /></td>
}