import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { Field, Form, FormRenderProps } from '@progress/kendo-react-form';

import { apiClient } from '../../api/apiClient';
import {
  DROPDOWN_GET_ALLOFFICES, LICENCE_GET_BY_ID, LICENCE_POST, LICENCE_PUT
} from '../../api/apiEndpoints';
import LicenceDto from '../../models/dto/Common/Admin/LicenceDto';
import LicenceResponse from '../../models/dto/Common/Admin/LicenceResponse';
import AppModulesEnum from '../../models/dto/Common/AppModulesEnum';
import DropdownItemResponse from '../../models/dto/Dropdown/DropdownItemResponse';
import EditLicenceRequest from '../../models/dto/Request/Admin/EditLicenceRequest';
import NotificationEnum from '../../models/enums/NotificationEnum';
import Licence from '../../models/local/Admin/Licence';
import DropdownItem from '../../models/local/Dropdown/DropdownItem';
import { mapper } from '../../models/mapper';
import Loading from '../../pages/Loading';
import { DictByType, DictTypes } from '../../scripts/dictProvider';
import { AppContext, IAppContext } from '../../services/AppContext';
import useAppNotifications from '../../services/AppNotifications';
import { requiredValidator } from '../../validators/CommonValidators';
import {
  FormDatePicker, FormDropDownList, FormMultiSelect, FormNumericTextBox
} from '../Kendo/form-components';
import LicenceEditToolbar from './LicenceEditToolbar';

interface ViewState {
  isLoading: boolean;
  licenceData?: Licence;
  offices?: DropdownItem[];
}

const INITIAL_VIEW_STATE: ViewState = {
  isLoading: true,
  licenceData: new Licence(),
  offices: []
};

const LicenceEdit: React.FC = () => {
  const formRef = React.useRef<Form>(null);
  const navigate = useNavigate();
  const notifications = useAppNotifications();
  const { id: licenceId } = useParams<{ id: string }>();

  const [viewState, setViewState] = React.useState<ViewState>(INITIAL_VIEW_STATE);
  const context = React.useContext<IAppContext>(AppContext);

  const getDropdownOffices = async (): Promise<DropdownItem[] | undefined> => {
    try {
      const resp = await apiClient({
        method: "GET",
        url: DROPDOWN_GET_ALLOFFICES,
        data: {},
      });

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      return mapper.mapArray(
        resp.data.result as DropdownItemResponse[],
        DropdownItem,
        DropdownItemResponse
      );
    } catch (error) {
      notifications.showNotification('Failed to fetch offices', NotificationEnum.Error);
      console.log("Error", error)
      return;
    }
  };

  const getLicenceData = async (): Promise<Licence | undefined> => {
    if (!licenceId) {
      notifications.showNotification('Licence ID is required to fetch licence data.', NotificationEnum.Error);
      navigate("/");
      return;
    }

    try {
      const resp = await apiClient({
        method: "GET",
        url: LICENCE_GET_BY_ID(licenceId),
        data: {},
      });

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      const result: LicenceResponse = resp.data.result as LicenceResponse;

      const mappedResult: Licence = mapper.map<LicenceResponse, Licence, IAppContext>(
        result,
        Licence,
        LicenceResponse,
        { extraArguments: context }
      );

      return mappedResult;

    } catch (error) {
      notifications.showNotification('Failed to fetch licence data', NotificationEnum.Error);
      console.log(error)
      return;
    }
  };

  React.useEffect(() => {
    Promise.all([getDropdownOffices(), getLicenceData()]).then(([resOffices, resLicence]) => {
      setViewState({ ...viewState, isLoading: false, offices: resOffices, licenceData: resLicence });
    });
  }, []);

  const submitForm = (form: any) => {
    const dtoToSend: EditLicenceRequest = mapper.map(form as Licence, EditLicenceRequest, Licence);

    apiClient({
      method: dtoToSend.id ? "PUT" : "POST",
      url: dtoToSend.id ? LICENCE_PUT : LICENCE_POST,
      data: dtoToSend,
    }).then(async (resp) => {
      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      } else {
        navigate("/");
        notifications.showNotification(
          dtoToSend.id
            ? "Zmiany w licencji zostały poprawnie zapisane."
            : "Nowa licencja została poprawnie zapisana.",
          NotificationEnum.Success
        );
      }
    });
  };

  const onSubmitButtonClick = (formProps: FormRenderProps, e: any) => {
    // If no changes in edit mode - return to list
    if (licenceId && formRef.current && Object.keys(formRef.current.modified).length === 0) {
      navigate("/");
    }

    // Validate form before submission
    if (!formRef.current?.isValid()) {
      formProps.onSubmit(e);
      notifications.showNotification(
        "Zapisanie danych nie udało się ponieważ formularz zawiera błędy.",
        NotificationEnum.Error
      );
    }

    formProps.onSubmit(e);
  };

  if (viewState.isLoading) {
    return <Loading />;
  }

  return (
    <div className="fullPageContentWrapper">
      <div className="fullWidthColumn">
        <Form
          onSubmit={submitForm}
          initialValues={viewState.licenceData}
          ref={formRef}
          render={(formRenderProps: FormRenderProps) => (
            <>
              <div className="mainToolbar">
                <div className="itemHeader">
                  {licenceId === undefined
                    ? "Tworzenie nowej licencji"
                    : `Edycja danych licencji dla ${viewState.licenceData?.office?.text}`}
                </div>
                <LicenceEditToolbar
                  onSubmitClick={(e: any) => onSubmitButtonClick(formRenderProps, e)}
                />
              </div>

              <Field
                id="office"
                name="office"
                label="Biuro"
                component={FormDropDownList}
                data={viewState.offices}
                validator={requiredValidator}
                textField="text"
                dataItemKey="id"
                disabled={true}
              />

              <Field
                id="validFrom"
                name="validFrom"
                autoComplete="off"
                label="Ważna od"
                component={FormDatePicker}
              />

              <Field
                id="validTo"
                name="validTo"
                autoComplete="off"
                label="Ważna do"
                component={FormDatePicker}
              />

              <Field
                id="workersLimit"
                name="workersLimit"
                autoComplete="off"
                label="Limit pracowników"
                component={FormNumericTextBox}
              />

              <br />

              <Field
                name="availableModules"
                id="availableModules"
                label="Dostępne moduły"
                data={DictByType(DictTypes.AppModulesEnum, context.values.dicts)}
                placeholder="wskaż minimum jeden..."
                textField="name"
                dataItemKey="id"
                component={FormMultiSelect}
                style={{ width: "400px" }}
              />
            </>
          )}
        />
      </div>
    </div>
  );
};

export default LicenceEdit;