import React, { useState } from 'react';

import { apiClient } from '../api/apiClient';
import { DICT_DELETE, DICT_POST_ADD, DICT_PUT_EDIT } from '../api/apiEndpoints';
import AddEditDictionaryItem from '../components/Settings/Dictionaries/AddEditDictionaryItem';
import DictionariesList from '../components/Settings/Dictionaries/DictionariesList';
import AddDictItemRequestDto from '../models/dto/Request/Dict/AddDictItemRequestDto';
import EditDictItemRequestDto from '../models/dto/Request/Dict/EditDictItemRequestDto';
import DictResponse from '../models/dto/Response/Dict/DictResponse';
import NotificationEnum from '../models/enums/NotificationEnum';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';
import useSessionWrapper from '../services/SessionWrapper';
import Loading from './Loading';

const SettingsDictionaries = () => {
  const [parentDict, setParentDict] = useState<[string, string]>(["", ""]);
  const loader = useAppLoader(false);
  const notifications = useAppNotifications();
  const sessionWrapper = useSessionWrapper();

  enum EditState {
    List,
    Add,
    Edit
  }

  const [viewState, setViewState] = React.useState<{ editState: EditState, editedEntity: DictResponse | undefined }>(
    {
      editState: EditState.List,
      editedEntity: undefined,
    });


  const showList = () => {
    setViewState({ ...viewState, editState: EditState.List, editedEntity: undefined });
  };

  const hendleSwitchToAdd = () => {
    setViewState({ ...viewState, editState: EditState.Add, editedEntity: undefined });
  };

  const handleSwitchToEdit = (entity: DictResponse) => {
    setViewState({ ...viewState, editState: EditState.Edit, editedEntity: entity });
  };

  const handleDictChange = (selectedParentDict: [string, string]) => {
    setParentDict(selectedParentDict);
  };

  const handleOnAddSave = (entity: DictResponse) => {
    loader.showLoading(true);

    const dtoToSend: AddDictItemRequestDto = new AddDictItemRequestDto();
    dtoToSend.name = entity.name;
    dtoToSend.parentId = parentDict[1];

    apiClient({
      method: "POST",
      url: DICT_POST_ADD,
      data: dtoToSend,
    }).then(async (resp) => {
      showList();
      loader.showLoading(false);

      if (resp.status === 200) {
        notifications.showNotification("Pozycja słownika została poprawnie zapisana.", NotificationEnum.Success);
        await sessionWrapper.localStorageToContextCopy();

      } else {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }
    });
  };

  const handleOnEditSave = (entity: DictResponse) => {
    loader.showLoading(true);

    const dtoToSend: EditDictItemRequestDto = new EditDictItemRequestDto();
    dtoToSend.id = entity.id;
    dtoToSend.name = entity.name;
    dtoToSend.parentId = parentDict[1];

    apiClient({
      method: "PUT",
      url: DICT_PUT_EDIT,
      data: dtoToSend,
    }).then(async (resp) => {
      loader.showLoading(false);
      showList();

      if (resp.status === 200) {
        notifications.showNotification("Zmiany w słowniku zostały poprawnie zapisane.", NotificationEnum.Success);
        await sessionWrapper.localStorageToContextCopy();
      } else {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }
    });
  };

  const handleOnDelete = (entity: DictResponse) => {
    loader.showLoading(true);

    apiClient({
      method: "DELETE",
      url: `${DICT_DELETE(entity.id)}`,
      data: {},
    }).then(async (resp) => {
      loader.showLoading(false);
      showList();

      if (resp.status === 200) {
        notifications.showNotification(`Pozycja słownika "${entity.name}" została usunięta.`, NotificationEnum.Success);
        await sessionWrapper.localStorageToContextCopy();
      } else {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }
    });

  }

  return (
    <div className="fullPageContentWrapper">
      <div className="fullWidthColumn">
        {loader.isLoading() ? <Loading /> :
          <>
            {viewState.editState === EditState.List && <DictionariesList onDictChange={handleDictChange} onAdd={hendleSwitchToAdd} onEdit={handleSwitchToEdit} onDelete={handleOnDelete}></DictionariesList>}
            {viewState.editState === EditState.Add && <AddEditDictionaryItem parentDict={parentDict} onCancel={showList} onSave={handleOnAddSave}></AddEditDictionaryItem>}
            {viewState.editState === EditState.Edit && <AddEditDictionaryItem parentDict={parentDict} data={viewState.editedEntity} onCancel={showList} onSave={handleOnEditSave}></AddEditDictionaryItem>}
          </>
        }
      </div>
    </div >
  );
};

export default SettingsDictionaries;
