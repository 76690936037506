import DaysEnum from '../../enums/DaysEnum';

export default class WorkPlanItem {
  id: string;

  day: DaysEnum;
  enabled: boolean;

  hourFrom: Date;
  hourTo: Date;
  hoursSum: string | null;

  public constructor(day: DaysEnum | any, enabled: boolean | any) { // | any -> workaround for automapper casting problem
    this.day = day ?? DaysEnum.MONDAY;
    this.enabled = enabled ?? false;

    this.hourFrom = new Date(0, 0, 0, 8, 0, 0, 0);
    this.hourTo = new Date(0, 0, 0, 16, 0, 0, 0);
    this.hoursSum = "08:00";
  }

  // not supported by automapper :/
  // public constructor(day?: DaysEnum | undefined, enabled?: boolean | undefined) {
  //   this.day = day ?? DaysEnum.MONDAY;
  //   this.enabled = enabled ?? false;

  //   this.hourFrom = new Date(0, 0, 0, 8, 0, 0, 0);
  //   this.hourTo = new Date(0, 0, 0, 16, 0, 0, 0);
  //   this.hoursSum = "8";
  // }
}