import React from 'react';



import { LICENCE_GET } from '../api/apiEndpoints';
import { LicencesList } from '../components/Licences/LicencesList';

const Licences = () => {
  return (
    <div className="mdlRoot">
      <LicencesList url={LICENCE_GET} />
    </div>
  );
}

export default Licences;