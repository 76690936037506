import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { GridColumn as Column } from '@progress/kendo-react-grid';

import { apiClient } from '../../api/apiClient';
import { PROMOCODE_DELETE } from '../../api/apiEndpoints';
import PromoCodeListItemResponse from '../../models/dto/Response/Admin/PromoCodeListItemResponse';
import PromoCodeList from '../../models/local/PromoCode/PromoCodeList';
import { mapper } from '../../models/mapper';
import useAppNotifications from '../../services/AppNotifications';
import PromptDialog from '../Common/PromptDialog';
import { PaginatedDataGrid, PaginatedGridRef } from '../Kendo/PaginatedDataGrid';

interface GridRef {
  reload: () => void;
}

const PromoCodesList = (props: { url: string }) => {
  const navigate = useNavigate();
  const notifications = useAppNotifications();
  const gridRef = React.useRef<PaginatedGridRef>(null);

  const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [
      { field: "code", operator: "startswith", value: "" }
    ],
  };

  const initialSort: Array<SortDescriptor> = [
    { field: "validFromUtc", dir: "asc" },
  ];

  const [viewState, setViewState] = useState<{
    promptDialogVisible: boolean,
    selectedId: string | null,
    selectedName: string | null,
    errorMessage: string | null,
    filter: CompositeFilterDescriptor,
    sort: Array<SortDescriptor>
  }>({
    promptDialogVisible: false,
    selectedId: null,
    selectedName: null,
    errorMessage: null,
    filter: initialFilter,
    sort: initialSort
  });

  const onDeleteButtonClick = (id: string, name: string) => {
    setViewState({ ...viewState, selectedId: id, selectedName: name, promptDialogVisible: true });
  };

  const callbackMapping = (dtoData: PromoCodeListItemResponse[]) => {
    return mapper.mapArray(dtoData, PromoCodeList, PromoCodeListItemResponse);
  };

  const onDeletePromoCode = () => {
    apiClient({
      method: "DELETE",
      url: PROMOCODE_DELETE(viewState.selectedId),
      data: {},
    }).then(async (resp) => {
      setViewState({ ...viewState, promptDialogVisible: false });
      if (resp.status !== 200) {
        setViewState({ ...viewState, errorMessage: resp.data.errors });
        return;
      }
      notifications.showNotification("Kod promocyjny został usunięty.");
      gridRef.current?.reload();
    });
  };

  const GridOperationsCell = (props: any) => (
    <td>
      <Button
        fillMode="outline"
        icon="edit"
        onClick={() => navigate(`/promocodes/edit/${props.dataItem.id}`)}
      />
      {" "}
      <Button
        fillMode="outline"
        icon="trash"
        themeColor="error"
        onClick={() => onDeleteButtonClick(props.dataItem.id, props.dataItem.code)}
      />
    </td>
  );

  return (
    <div className="mdlMain">
      <div className="mdlToolbar">
        <Toolbar>
          <ToolbarItem>
            <Button
              icon="plus"
              fillMode="outline"
              onClick={() => navigate("/promocodes/edit")}
            >
              Dodaj
            </Button>
          </ToolbarItem>
        </Toolbar>
      </div>

      <div className="mdlDataView">
        <PaginatedDataGrid
          ref={gridRef}
          url={props.url}
          filterable={true}
          filter={viewState.filter}
          sortable={true}
          sort={viewState.sort}
          callbackMapping={callbackMapping}
        >
          <Column field="code" title="Kod promocyjny" />
          <Column field="workersLimit" title="Limit pracowników" />
          <Column field="validFromUtc" title="Ważny od" format="{0:d}" filter="date" />
          <Column field="validToUtc" title="Ważny do" format="{0:d}" filter="date" />
          <Column field="usages" title="Użycia" filter="numeric" />
          <Column field="maxUsages" title="Limit użyć" filter="numeric" />
          <Column field="validForDays" title="Ważność w dniach" filter="numeric" />
          <Column
            title="Operacje"
            filterable={false}
            sortable={false}
            width="100px"
            cell={GridOperationsCell}
          />
        </PaginatedDataGrid>
      </div>

      {viewState.promptDialogVisible && (
        <PromptDialog
          text={`<p>Czy na pewno chcesz usunąć kod promocyjny <b>"${viewState.selectedName}"</b> ?</p>`}
          onYes={onDeletePromoCode}
          onNo={() => setViewState({ ...viewState, promptDialogVisible: false })}
        />
      )}
    </div>
  );
};

export default PromoCodesList;