import React from 'react';
import { useNavigate } from 'react-router';

import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';

const EmployeeQRToolbar = (props: any) => {
    const navigate = useNavigate();

    return (
        <Toolbar>
            <ToolbarItem>
                <Button
                    icon="qr-code"
                    fillMode="outline"
                    onClick={() => {
                        props.onGenerateAllClick && props.onGenerateAllClick();
                    }}
                >
                    Generuj zbiorczo kody QR
                </Button>
            </ToolbarItem>
            <ToolbarItem>
                <Button
                    icon="email"
                    fillMode="outline"
                    onClick={() => {
                        props.onGenerateAllClick && props.onGenerateAllClick();
                    }}
                >
                    Wyślij do wszystkich pracowników
                </Button>
            </ToolbarItem>
        </Toolbar>
    );
};

export default EmployeeQRToolbar;