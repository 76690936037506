import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import { apiClient } from '../api/apiClient';
import {
  LEAVE_GET_all, LEAVE_GET_considered, LEAVE_GET_forConsideration, LEAVE_GET_own
} from '../api/apiEndpoints';
import ApplicationGridTab from '../components/Leaves/ApplicationsList/ApplicationGridTab';
import LeaveListItemDto from '../models/dto/Leaves/LeaveListItemDto';
import LeaveStatusEnum from '../models/enums/LeaveStatusEnum';
import NotificationEnum from '../models/enums/NotificationEnum';
import UserRoleEnum from '../models/enums/UserRoleEnum';
import LeaveListItem from '../models/local/Leaves/LeaveListItem';
import { mapper } from '../models/mapper';
import { AppContext, IAppContext } from '../services/AppContext';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';

enum TabType {
  FOR_CONSIDERATION = 'FOR_CONSIDERATION',
  OWN = 'OWN',
  CONSIDERED = 'CONSIDERED',
  ALL = 'ALL'
}

interface TabData {
  key: TabType;
  title: string;
  data: LeaveListItem[] | undefined;
  buttonAction: (id: string) => void;
  buttonLabel: string;
  isLoaded: boolean;
  showYearSelector?: boolean;
  showMonthSelector?: boolean;
}

const AppsList = () => {
  const context = useContext<IAppContext>(AppContext);
  const navigate = useNavigate();
  const loader = useAppLoader(true);
  const notifications = useAppNotifications();

  const canViewForConsideration = context.values.role === UserRoleEnum.MANAGER || context.values.role === UserRoleEnum.HR;
  const canViewAllLeaves = context.values.role === UserRoleEnum.HR;

  // Initialize tabs configuration and year
  const [year, setYear] = React.useState<number>(new Date().getFullYear());
  const [month, setMonth] = React.useState<number | null>(null); // null oznacza "Wszystkie"
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);

  const [tabs, setTabs] = React.useState<TabData[]>(() => {
    const availableTabs: TabData[] = [];

    if (canViewForConsideration) {
      availableTabs.push({
        key: TabType.FOR_CONSIDERATION,
        title: 'Oczekujące na Twoją decyzję (kierownik)',
        data: undefined,
        buttonAction: (id: string) => navigate(`/appslist/${id}/consider`),
        buttonLabel: 'Rozpatrz',
        isLoaded: false,
        showYearSelector: false,
        showMonthSelector: false
      });
    }

    availableTabs.push({
      key: TabType.OWN,
      title: 'Złożone przez Ciebie wnioski',
      data: undefined,
      buttonAction: (id: string) => navigate(`/appslist/${id}`),
      buttonLabel: 'Szczegóły',
      isLoaded: false,
      showYearSelector: true,
      showMonthSelector: false
    });

    if (canViewForConsideration && !canViewAllLeaves) {
      availableTabs.push({
        key: TabType.CONSIDERED,
        title: 'Rozpatrzone wnioski (w działach kierownika)',
        data: undefined,
        buttonAction: (id: string) => navigate(`/appslist/${id}`),
        buttonLabel: 'Szczegóły',
        isLoaded: false,
        showYearSelector: true,
        showMonthSelector: false
      });
    }

    if (canViewAllLeaves) {
      availableTabs.push({
        key: TabType.ALL,
        title: 'Wszystkie wnioski (raport HR)',
        data: undefined,
        buttonAction: (id: string) => navigate(`/appslist/${id}`),
        buttonLabel: 'Szczegóły',
        isLoaded: false,
        showYearSelector: true,
        showMonthSelector: true
      });
    }

    return availableTabs;
  });

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  // Data fetching function
  const fetchData = async (tabKey: TabType, fetchYear?: number, fetchMonth?: number | null) => {
    const currentTab = tabs.find(tab => tab.key === tabKey);
    const yearToFetch = fetchYear ?? year;
    const monthToFetch = fetchMonth !== undefined ? fetchMonth : month;

    try {
      loader.showLoading(true);
      let result: LeaveListItem[] | undefined;

      switch (tabKey) {
        case TabType.OWN:
          const ownResponse = await apiClient({
            method: "GET",
            url: LEAVE_GET_own(yearToFetch),
            data: {},
          });
          result = mapper.mapArray(ownResponse?.data?.result, LeaveListItem, LeaveListItemDto);
          break;

        case TabType.FOR_CONSIDERATION:
          if (canViewForConsideration) {
            const forConsiderationResponse = await apiClient({
              method: "GET",
              url: LEAVE_GET_forConsideration,
              data: {},
            });
            result = mapper.mapArray(forConsiderationResponse?.data?.result, LeaveListItem, LeaveListItemDto);
          }
          break;

        case TabType.CONSIDERED:
          if (canViewForConsideration) {
            const consideredResponse = await apiClient({
              method: "GET",
              url: LEAVE_GET_considered(yearToFetch),
              data: {},
            });
            result = mapper.mapArray(consideredResponse?.data?.result, LeaveListItem, LeaveListItemDto);
          }
          break;

        case TabType.ALL:
          if (canViewAllLeaves) {
            const allResponse = await apiClient({
              method: "GET",
              url: LEAVE_GET_all(yearToFetch, monthToFetch),
              data: {},
            });
            result = mapper.mapArray(allResponse?.data?.result, LeaveListItem, LeaveListItemDto);
          }
          break;
      }

      setTabs(prevTabs =>
        prevTabs.map(tab =>
          tab.key === tabKey
            ? { ...tab, data: result, isLoaded: true }
            : tab
        )
      );

    } catch (error) {
      notifications.showNotification(
        `Wystąpił nieoczekiwany błąd podczas ładowania danych. ${error}`,
        NotificationEnum.Error
      );
    } finally {
      loader.showLoading(false);
    }
  };

  // Handle tab selection
  const handleTabSelect = (e: { selected: number }) => {
    const selectedTabData = tabs[e.selected];

    // Only fetch if not already loaded
    if (!selectedTabData.isLoaded) {
      fetchData(selectedTabData.key);
    }
    setSelectedTab(e.selected);
  };

  // Initial data load for the first tab
  useEffect(() => {
    if (!isInitialized && tabs.length > 0) {
      fetchData(tabs[0].key);
      setIsInitialized(true);
    }
  }, [isInitialized, tabs]);

  // Handler for year change
  const handleYearChange = (newYear: number) => {
    setYear(newYear);

    // Reset isLoaded flag for all tabs to force reload when they are selected
    setTabs(prevTabs =>
      prevTabs.map(tab => ({
        ...tab,
        isLoaded: false
      }))
    );

    // Force reload data for current tab with new year
    const currentTab = tabs[selectedTab];
    if (currentTab) {
      fetchData(currentTab.key, newYear, month);
    }
  };

  // Handler for month change
  const handleMonthChange = (newMonth: number | null) => {
    setMonth(newMonth);

    // Reset isLoaded flag for the current tab to force reload
    const currentTabKey = tabs[selectedTab]?.key;
    if (currentTabKey === TabType.ALL) {
      setTabs(prevTabs =>
        prevTabs.map(tab =>
          tab.key === TabType.ALL
            ? { ...tab, isLoaded: false }
            : tab
        )
      );

      // Force reload data for ALL tab with new month
      fetchData(TabType.ALL, year, newMonth);
    }
  };

  // Update awaiting notifications indicator
  useEffect(() => {
    if (!loader.isLoading()) {
      const forConsiderationTab = tabs.find(t => t.key === TabType.FOR_CONSIDERATION);
      if (forConsiderationTab?.data) {
        const awaitingCount = forConsiderationTab.data.filter(
          l => l.status as LeaveStatusEnum === LeaveStatusEnum.AWAITING
        ).length;

        if (awaitingCount > 0) {
          const elements = document.querySelectorAll('div.k-tabstrip-items-wrapper > ul > li:nth-child(1)');
          if (elements?.length > 0) {
            elements[0].setAttribute('hasData', 'true');
          }
        }
      }
    }
  }, [loader.isLoading(), tabs]);

  return (
    <>
      {!loader.isLoading() && (
        <div className="fullPageContentWrapper">
          <div className="fullWidthColumn" id="leaveList">
            <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
              {tabs.map((tab, index) => (
                <TabStripTab key={tab.key} title={tab.title}>
                  <ApplicationGridTab
                    data={tab.data}
                    buttonAction={tab.buttonAction}
                    buttonLabel={tab.buttonLabel}
                    icon=""
                    year={year}
                    month={tab.key === TabType.ALL ? month : null}
                    yearChangedHandler={handleYearChange}
                    monthChangedHandler={tab.key === TabType.ALL ? handleMonthChange : undefined}
                    showYearSelector={tab.showYearSelector}
                    showMonthSelector={tab.showMonthSelector}
                  />
                </TabStripTab>
              ))}
            </TabStrip>
          </div>
        </div>
      )}
    </>
  );
};

export default AppsList;