import React, { useState } from 'react';

import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';

const YearSelector = (props: {
  initialYear?: number,
  availableYears?: number[],
  onValueChanged: (selectedYear: number) => void,
  readonly: boolean,
  showLabel?: boolean,
}) => {
  const currentYear = props.initialYear ?? new Date().getFullYear();
  const defaultYears = [{ value: currentYear - 2 }, { value: currentYear - 1 }, { value: currentYear }, { value: currentYear + 1 }, { value: currentYear + 2 }];
  const [selectedYear, setSelectedYear] = useState<{ value: number }>({ value: currentYear });

  const handleYearChange = async (event: DropDownListChangeEvent) => {
    setSelectedYear({ ...selectedYear, value: event.target.value.value });
    props.onValueChanged(event.target.value.value);
  };

  return (
    <>
      <div>{props.showLabel === true && (<>Wybierz rok: </>)}
        <DropDownList data={props.availableYears ?? defaultYears} dataItemKey="value" textField="value" value={selectedYear} onChange={handleYearChange} style={{ width: "120px" }} disabled={props.readonly} /></div>
    </>
  );
};

export default YearSelector;
