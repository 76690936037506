import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';

import { apiClient } from '../api/apiClient';
import { SESSION_POST_login } from '../api/apiEndpoints';
import { FormInput } from '../components/Kendo/form-components';
import PromoBanner from '../components/LoginRegister/PromoBanner';
import LoginResponse from '../models/dto/Response/Session/LoginResponse';
import NotificationEnum from '../models/enums/NotificationEnum';
import useAppLoader from '../services/AppLoader';
import useAppNotifications from '../services/AppNotifications';
import useSessionWrapper from '../services/SessionWrapper';
import loginModuleStyle from '../styles/Login.module.scss';
import moduleStyle from '../styles/LoginRegister.module.scss';
import { emailValidator, requiredValidator } from '../validators/CommonValidators';

interface LoginProps {
  returnUrl?: string;
}

const Login = ({ returnUrl }: LoginProps) => {
  const loader = useAppLoader(false);
  const navigate = useNavigate();

  const notifications = useAppNotifications();
  const sessionWrapper = useSessionWrapper();

  // explain handleSubmit function

  const handleSubmit = async (loginForm: any) => {
    loader.showLoading(true);

    apiClient({
      method: "POST",
      url: SESSION_POST_login,
      data: {
        email: loginForm.email,
        password: loginForm.password,
      },
    }).then(async (resp) => {
      loader.showLoading(false);

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      const result = resp?.data?.result as LoginResponse;
      sessionWrapper.login(
        result?.token,
        `${result?.firstName} ${result?.lastName}`,
        result?.id,
        result?.role.toString().toUpperCase(),
        result?.licenceExpirationDays,
        result?.systemInfo.currentDateTime,
        result?.specialId,
        result?.avatarId,
        returnUrl
      );
    });
  };

  useEffect(() => {
    if (returnUrl) {
      notifications.showNotification("Dostęp do strony wymaga wcześniejszego zalogowania się do systemu.", NotificationEnum.Error);
    }

    const updateContext = async () => {
      await sessionWrapper.clearLocalStorageExceptMobileSettings();
    }
    updateContext();
  }, [])

  return (
    <div className={moduleStyle.pageWrapper}>
      <div className={moduleStyle.formColumn}>
        <Form
          onSubmit={handleSubmit}
          render={() => (
            <div style={{ width: "400px" }}>
              <p className={moduleStyle.formHeader}>Logowanie</p>

              <FormElement
                style={{
                  width: 400,
                }}
              >
                <div>
                  <Field
                    id={"email"}
                    name={"email"}
                    autoComplete="off"
                    label={"Adres e-mail"}
                    component={FormInput}
                    validator={emailValidator}
                  />

                  <Field
                    id={"password"}
                    name={"password"}
                    type="password"
                    autoComplete="off"
                    label={"Hasło"}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div style={{ width: "50%" }}>
                    <Button type="submit" disabled={loader.isLoading()} style={{}} themeColor='success' >Zaloguj</Button>
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    <Button disabled={loader.isLoading()} onClick={() => { navigate("/resetPasswordRequest"); }} fillMode='link'>Zresetuj hasło</Button>
                    {/* <Link to="/resetPasswordRequest">
                      Odzyskaj hasło
                    </Link> */}
                  </div>
                </div>
                <p style={{ textAlign: "center", paddingTop: "15px" }}>
                  <div className={loginModuleStyle.goToRegistrationButton} onClick={() => { navigate("/Register"); }} >
                    <a href="/#/Register" >
                      <span style={{ textDecoration: "none" }}>Kliknij tutaj, aby założyć<br /><b>bezpłatne</b> konto testowe</span>
                    </a>
                  </div>
                </p>
              </FormElement>
            </div>
          )}
        />
      </div>
      <PromoBanner />
    </div >
  );
};

export default Login;
