import React, { useEffect, useState } from 'react';

import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';

import { apiClient } from '../../../api/apiClient';
import { DICT_GET_editable } from '../../../api/apiEndpoints';
import DictResponse from '../../../models/dto/Response/Dict/DictResponse';
import Loading from '../../../pages/Loading';
import useAppLoader from '../../../services/AppLoader';
import PromptDialog from '../../Common/PromptDialog';

const DictionariesList = (props: { onDictChange: (selectedDict: [string, string]) => void, onAdd: () => void, onEdit: (entity: DictResponse) => void, onDelete: (entity: DictResponse) => void }) => {
  const [dicts, setDicts] = useState<DictResponse[]>([]);
  const [selectedDict, setSelectedDict] = useState<DictResponse>();
  const [deleteDialogState, setDeleteDialogState] = useState<DictResponse | undefined>();

  const loader = useAppLoader(true);

  useEffect(() => {
    loader.showLoading(true);

    apiClient({
      method: "GET",
      url: DICT_GET_editable,
      data: {},
    }).then((resp) => {
      loader.showLoading(false);

      setDicts(resp?.data?.result as DictResponse[]);
      setSelectedDict(resp?.data?.result[0]);
    });
  }, []);

  useEffect(() => {
    if (selectedDict != null)
      props.onDictChange([selectedDict!.name, selectedDict!.id]);
  }, [selectedDict])

  const handleSelectedDictChange = (event: DropDownListChangeEvent) => {
    setSelectedDict(event.target.value);
  };

  const getDictItems = () => {
    return selectedDict!.items;
  };

  return (
    <>
      {
        loader.isLoading() ? <Loading /> :
          <>

            {deleteDialogState !== undefined && (
              <PromptDialog
                text={`<p>Czy na pewno chcesz usunąć <b>"${deleteDialogState.name}</b> z listy <b>"${selectedDict!.name}"</b> ?</p><br/>
                <p style="color:red">Usunięcie tej pozycji spowoduje, że zniknie ona także ze wszystkich miejsc, w których została użyta, tzn. jeżeli usuniesz wpis dotyczący formy zatrudnienia, np. Umowa o pracę, to wszystkie wpisy powiązane z tą pozycją zostaną uzunięte z kartotek pracowników.</p>
                <p style="color:red">Operacja ta jest nieodwracalna! <b>Czy na pewno chcesz usunąć tę pozycję?</b></p>
                `}
                onYes={() => { props.onDelete(deleteDialogState); setDeleteDialogState(undefined); }}
                onNo={() => { setDeleteDialogState(undefined) }}
              />
            )}

            <p>
              Wybrany słownik: {" "}
              {dicts && (
                <DropDownList
                  id="selectedDict"
                  data={dicts}
                  textField="name"
                  dataItemKey="id"
                  value={selectedDict}
                  onChange={handleSelectedDictChange}
                  style={{ width: "400px" }}
                />
              )}
            </p>

            <div className="mainToolbar" style={{ minHeight: "3em" }}>
              <Toolbar>
                <ToolbarItem>
                  <Button
                    fillMode="outline"
                    icon="page-properties"
                    onClick={props.onAdd}
                  >
                    Dodaj nową pozycję
                  </Button>
                </ToolbarItem>

                <ToolbarSeparator />

              </Toolbar>
            </div>

            {selectedDict && (
              <Grid className="belowToolbarWrapper" data={getDictItems()}>
                {/* <Column field="id" title="Id" width="200px" /> */}
                <Column field="name" title="Nazwa elementu na liście" />
                <Column
                  title="Operacje"
                  width="200px"
                  cell={(cellProps: GridCellProps) => (
                    <td>
                      {(cellProps.dataItem as DictResponse).isEditable === true ?
                        <>
                          <Button
                            fillMode="outline"
                            icon="pencil"
                            onClick={() => { props.onEdit(cellProps.dataItem as DictResponse) }}
                          >Edytuj</Button>
                          &nbsp;
                          <Button
                            fillMode="outline"
                            icon="trash"
                            onClick={() => {
                              setDeleteDialogState(cellProps.dataItem as DictResponse)
                            }}
                          >Usuń</Button>

                        </> :
                        <span>nieedytowalny</span>
                      }
                    </td>
                  )}
                />
              </Grid>
            )}
          </>
      }
    </>
  );
};
export default DictionariesList;
