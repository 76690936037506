import React from 'react';

import { Button, Toolbar, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';
import {
  Grid, GridCellProps, GridColumn as Column, GridDetailRowProps
} from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { GridPDFExport } from '@progress/kendo-react-pdf';

import { apiClient } from '../../api/apiClient';
import { LEAVE_GET_yearlyReport, UTILS_GET_systemInfo } from '../../api/apiEndpoints';
import { CLimitType_Vacation } from '../../models/consts/constants';
import YearlyLeaveReportItemResponse from '../../models/dto/Response/Leave/YearlyLeaveReportItemResponse';
import YearlyLeaveReportResponse from '../../models/dto/Response/Leave/YearlyLeaveReportResponse';
import YearlyLeaveReportTypeGroup from '../../models/dto/Response/Leave/YearlyLeaveReportTypeGroup';
import YearlyLeaveReportUserGroup from '../../models/dto/Response/Leave/YearlyLeaveReportUserGroup';
import SystemInfo from '../../models/dto/Response/Session/SystemInfo';
import { LimitTypeScopeEnum } from '../../models/enums/LimitTypeScopeEnum';
import NotificationEnum from '../../models/enums/NotificationEnum';
import Loading from '../../pages/Loading';
import useAppNotifications from '../../services/AppNotifications';
import PrintFooter from '../_common/CustomProgressBar/PrintFooter';
import YearSelector from '../Common/YearSelector';

interface ViewState {
  gridData?: YearlyLeaveReportUserGroup[];
  isLoading: boolean;
  year: number;
  showSummaryOnly: boolean;
  currentDate: Date | undefined;
}

const INITIAL_VIEW_STATE: ViewState = {
  isLoading: true,
  year: 2025,
  showSummaryOnly: false,
  currentDate: undefined
};


const LeaveDetailsCell = (props: GridCellProps) => {
  const dataItem = props.dataItem.leaves as YearlyLeaveReportItemResponse[];

  const formatDate = (date: Date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();

    return `${year}-${month}-${day}`;
  };

  return (
    <td>
      <Grid
        data={dataItem}
        style={{}}
      >
        <Column
          field="dateRange"
          title="Zakres dat"
          width={"200px"}
          cell={(props) => (
            <td>
              {formatDate(props.dataItem.startDate)} - {formatDate(props.dataItem.endDate)}
            </td>
          )}
        />
        <Column
          field="workingDays"
          title="Ilość dni"
        />
      </Grid>
    </td>
  );
};

const LimitTypeDetailGrid = (props: GridDetailRowProps) => {
  const data: YearlyLeaveReportTypeGroup[] = props.dataItem.leaveTypeGroups;

  if (data) {
    return (
      <Grid data={data} >
        <Column field="limitTypeName" title="Rodzaj" width="200px" />
        <Column
          field="typeTotalAvailable"
          title="Należny"
          width="100px"
          cell={(props) => (
            <td>
              {props.dataItem.limitScope !== LimitTypeScopeEnum.NoLimit ? props.dataItem.typeTotalAvailable : '(bez limitu)'}
            </td>
          )}
        />
        <Column field="typeTotalWorkingDays" title="Wykorzystany" width="120px" />
        <Column
          field="typeTotalWorkingDays"
          title="Pozostały"
          width="100px"
          cell={(props) => {
            const available = props.dataItem.typeTotalAvailable;
            const used = props.dataItem.typeTotalWorkingDays;
            const remaining = props.dataItem.limitScope !== LimitTypeScopeEnum.NoLimit ? available - used : '---';
            return (
              <td>{remaining}</td>
            );
          }}
        />
        <Column field="typeTotalWorkingDays" title="Szczegóły" cell={LeaveDetailsCell} />
      </Grid>
    );
  }
  return (
    <div style={{ height: '50px', width: '100%' }}>
      <div style={{ position: 'absolute', width: '100%' }}>
        <div className="k-loading-image" />
      </div>
    </div>
  );
};

const SimplifiedLeaveGrid = ({ data }: { data?: YearlyLeaveReportUserGroup[] }) => {
  const findVacationData = (leaveTypeGroups: YearlyLeaveReportTypeGroup[]) => {
    return leaveTypeGroups.find(group => group.parentId === CLimitType_Vacation);
  };

  const prepareGridData = () => {
    if (!data) return [];

    return data.map(user => {
      const vacationData = findVacationData(user.leaveTypeGroups);
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        available: vacationData?.typeTotalAvailable || 0,
        used: vacationData?.typeTotalWorkingDays || 0,
        remaining: vacationData ?
          (vacationData?.limitScope.toString() !== LimitTypeScopeEnum.NoLimit ?
            vacationData.typeTotalAvailable - vacationData.typeTotalWorkingDays :
            '---')
          : 0
      };
    });
  };

  return (
    <Grid
      id="simplifiedLeaveGrid"
      style={{ height: "100%" }}
      data={prepareGridData()}
    >
      <Column
        field="firstName"
        title="Imię"
        width="200px"
        cell={(props) => (
          <td style={{ fontWeight: 'bold' }}>
            {props.dataItem[props!.field!]}
          </td>
        )}
      />
      <Column
        field="lastName"
        title="Nazwisko"
        cell={(props) => (
          <td style={{ fontWeight: 'bold' }}>
            {props.dataItem[props!.field!]}
          </td>
        )}
      />
      <Column
        field="available"
        title="Należny"
        width="100px"
      />
      <Column
        field="used"
        title="Wykorzystany"
        width="120px"
      />
      <Column
        field="remaining"
        title="Pozostało"
        width="100px"
      />
    </Grid>
  );
};

const LeaveStateGrid = ({ data }: { data?: YearlyLeaveReportUserGroup[] }) => {
  return (
    <Grid
      id="leaveStatesGrid"
      style={{ height: "100%" }}
      data={data}
      detail={LimitTypeDetailGrid}
    >
      <Column
        field="firstName"
        title="Imię"
        width="200px"
        cell={(props) => (
          <td style={{ fontWeight: 'bold', borderTop: "1px solid black" }}>
            {props.dataItem[props!.field!]}
          </td>
        )}
      />
      <Column
        field="lastName"
        title="Nazwisko"
        cell={(props) => (
          <td style={{ fontWeight: 'bold', borderTop: "1px solid black" }}>
            {props.dataItem[props!.field!]}
          </td>
        )}
      />
    </Grid>
  );
};


export const LeavesStatesReport = () => {
  const [viewState, setViewState] = React.useState<ViewState>(INITIAL_VIEW_STATE);
  const notifications = useAppNotifications();

  const getSystemInfo = async (): Promise<SystemInfo | undefined> => {

    try {
      const resp = await apiClient({
        method: "GET",
        url: UTILS_GET_systemInfo,
        data: {},
      });

      if (resp.status !== 200) {
        notifications.showNotification("Wystąpił problem z pobraniem niezbędnych danych do przygotowania wydruku.", NotificationEnum.Error);
        return;
      }

      const result: SystemInfo = resp.data.result as SystemInfo;
      return result;
    } catch (error) {
      notifications.showNotification('Wystąpił nieoczekiwany błąd.', NotificationEnum.Error);
      return;
    }
  };


  const setYear = (year: number) => {
    setViewState(prev => ({ ...prev, year }));
  };

  let pdfExporter: GridPDFExport | null = null;
  const handleExportToPDF = () => {

    if (pdfExporter) {
      pdfExporter.save();
    }
  };

  const getYearlyReportData = async (): Promise<YearlyLeaveReportResponse | undefined> => {
    try {
      const resp = await apiClient({
        method: "GET",
        url: LEAVE_GET_yearlyReport(viewState.year),
        data: {},
      });

      if (resp.status !== 200) {
        notifications.showNotification(resp.data.errors, NotificationEnum.Error);
        return;
      }

      return resp.data.result as YearlyLeaveReportResponse;

    } catch (error) {
      notifications.showNotification('Failed to fetch yearly report data', NotificationEnum.Error);
      console.log(error)
      return;
    }
  };

  React.useEffect(() => {
    Promise.all([getYearlyReportData(), getSystemInfo()]).then(([resYearlyResponseData, systemInfo]) => {
      setViewState(prev => ({
        ...prev,
        gridData: resYearlyResponseData!.users,
        currentDate: systemInfo?.currentDateTime,
        isLoading: false
      }));
    });
  }, [viewState.year]);

  if (viewState.isLoading) {
    return <Loading />;
  }

  const gridContent = () => {
    return viewState.showSummaryOnly ? (
      <SimplifiedLeaveGrid data={viewState!.gridData} />
    ) : (
      <LeaveStateGrid data={viewState!.gridData} />
    );
  };

  const pageTemplate = (props: any) => {
    const formattedDate = viewState.currentDate
      ? new Date(viewState.currentDate).toLocaleDateString('pl-PL')
      : '-';

    return (
      <>
        <div
          style={{
            position: "absolute",
            top: "60px",
            left: "40px",
          }}
        >
          <p>
            STAN URLOPÓW W {viewState.year} ROKU<br />
            Stan na dzień {formattedDate}<br />
          </p>
        </div>
        <PrintFooter pageNum={props.pageNum} pagesTotal={props.totalPages} />
      </>
    );
  };

  return (
    (!viewState.isLoading &&
      <div className="mdlMain">
        <div className="mdlToolbar">
          <Toolbar>
            <ToolbarItem>
              <YearSelector
                initialYear={viewState.year}
                onValueChanged={(year) => {
                  setYear(year);
                }}
                readonly={false}
                showLabel={true}
              />
            </ToolbarItem>
            <ToolbarItem>
              <Checkbox
                label="Tylko podsumowanie"
                checked={viewState.showSummaryOnly}
                onChange={(e) => {
                  setViewState(prev => ({ ...prev, showSummaryOnly: e.value }));
                }}
              />
            </ToolbarItem>
            <ToolbarSpacer />
            <ToolbarItem>
              <Button
                icon="printer"
                fillMode="outline"
                onClick={handleExportToPDF}
              >
                PDF
              </Button>
            </ToolbarItem>
          </Toolbar>
        </div>

        <div className="mdlDataView">
          {gridContent()}
        </div>

        <GridPDFExport
          ref={(exporter) => pdfExporter = exporter}
          paperSize="A4"
          scale={0.5}
          margin={{ top: "25mm", right: "15mm", left: "10mm", bottom: "15mm" }}
          pageTemplate={pageTemplate}
        >
          {viewState.showSummaryOnly ? SimplifiedLeaveGrid({ data: viewState!.gridData }) : LeaveStateGrid({ data: viewState!.gridData })}
        </GridPDFExport>
      </div>)
  );
};