import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Field, Form, FormRenderProps } from '@progress/kendo-react-form';

import { apiClient } from '../../api/apiClient';
import { CLIENTLICENCE_GET } from '../../api/apiEndpoints';
import ClientLicenceResponse from '../../models/dto/Response/Admin/ClientLicenceResponse';
import NotificationEnum from '../../models/enums/NotificationEnum';
import ClientLicence from '../../models/local/Admin/ClientLicence';
import { mapper } from '../../models/mapper';
import ClientLicenceView from '../../pages/ClientLicenceView';
import useAppNotifications from '../../services/AppNotifications';
import ErrorMessage from '../Common/ErrorMessage';
import { FormDatePicker, FormInput, FormNumericTextBox } from '../Kendo/form-components';

const ClientLicenceInfo = (props: {}) => {
  const navigate = useNavigate();
  const notifications = useAppNotifications();

  const [viewState, setViewState] = React.useState<{
    isLoading: boolean,
    licenceData: ClientLicence | undefined
  }>(
    {
      isLoading: true,
      licenceData: new ClientLicence()
    }
  );

  const getClientLicence = async () => {
    const resp = await apiClient({
      method: "GET",
      url: CLIENTLICENCE_GET,
      data: {},
    });

    if (resp.status !== 200) {
      notifications.showNotification(resp.data.errors, NotificationEnum.Error);
      return;
    }

    const result: ClientLicenceResponse = resp.data.result as ClientLicenceResponse;
    const mappedResult: ClientLicence = mapper.map(result, ClientLicence, ClientLicenceResponse);

    return mappedResult;
  }

  useEffect(() => {
    Promise.all([getClientLicence()]).then(([resLicence]) => {
      setViewState({ ...viewState, isLoading: false, licenceData: resLicence });
    });
  }, []);

  return (
    <>
      <div className="fullPageContentWrapper">
        <div className="fullWidthColumn">
          {viewState.isLoading === false && (
            <Form
              initialValues={viewState.licenceData}
              //initialValues={licenceData}
              //key={JSON.stringify(viewState.licenceData)}
              render={(formRenderProps: FormRenderProps) => (
                <>
                  <div>
                    <div className={"itemHeader" + (!viewState.licenceData?.isValid ? " errorText" : "")}>
                      {viewState.licenceData?.isValid
                        ? `Licencja jest ważna do dnia ${viewState.licenceData?.validTo.toLocaleDateString()}`
                        : `Licencja wygasła z dniem ${viewState.licenceData?.validTo.toLocaleDateString()}`}
                    </div>
                  </div>

                  <Field
                    id={"officeName"}
                    name={"officeName"}
                    label={"Biuro"}
                    component={FormInput}
                    readOnly={true}
                  />
                  <Field
                    id={"validFrom"}
                    name={"validFrom"}
                    autoComplete="off"
                    label={"Ważna od"}
                    component={FormDatePicker}
                    disabled={true}
                  />
                  <Field
                    id={"validTo"}
                    name={"validTo"}
                    autoComplete="off"
                    label={"Ważna do"}
                    component={FormDatePicker}
                    disabled={true}
                  />
                  <Field
                    id={"workersLimit"}
                    name={"workersLimit"}
                    autoComplete="off"
                    label={"Limit pracowników"}
                    component={FormNumericTextBox}
                    readOnly={true}
                  />
                </>
              )}
            />)}
        </div>
      </div>
    </>
  )
}

export default ClientLicenceInfo;